import React from "react";
import "../Softdevbanner/Softdevbanner.css";
import Button from "react-bootstrap/Button";
import softdevvbannerimg from "../Assets/softbanner2.jpg";

const Softdevbanner = () => {
  return (
    <section>
      <div className="softdevbanner-container">
        <img src={softdevvbannerimg} alt="" />
        <div className="softdevbanner-container-content text-center text-white">
          <h1>Software Product Development </h1>
          <p className="">
            As a leading software product engineering firm, we optimize business
            operations, elevate customer experiences, and drive growth through
            cutting-edge software solutions. Our comprehensive product
            development services cater to diverse industries, equipping
            organizations with the tools they need to stay ahead of the
            competition.
          </p>
          <div className=" softdevbanner-button text-center p-3">
            <a
              href="https://wa.me/+919618800449"
              rel="noreferrer"
              target="_blank"
            >
              <Button variant="light" className="rounded-5   px-4 py-2">
                Let's Talk
              </Button>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Softdevbanner;
