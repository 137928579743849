import React, { useEffect, useState } from "react";
import "../Header/Header.css";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";

import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link } from "react-router-dom";

const Header = () => {
  const [fixed, setFixed] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      window.scrollY > 50 ? setFixed(true) : setFixed(false);
    });
  }, []);

  return (
    <section className="header-section ">
      <div className={`header-container fixed-top ${fixed ? "dark-nav" : ""} `}>
        {["lg"].map((expand) => (
          <Navbar
            key={expand}
            expand={expand}
            className="px-5 "
            data-bs-theme="dark"
          >
            <Container fluid className="fs-6 pe-5 dgColor">
              <Navbar.Brand href="/" className="fs-3">
                <strong>Cornerstone</strong>
              </Navbar.Brand>
              <Navbar.Toggle
                aria-controls={`offcanvasNavbar-expand-${expand}`}
                class="dgColor"
              />
              <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-${expand}`}
                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                placement="end"
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                    <strong>Cornerstone</strong>
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body >
                  <Nav className="justify-content-end flex-grow-1 pe-3">
                    <Nav.Link className="px-3 fs-5 " href="/">
                      <Link
                        className="navbutton"
                        to={"/"}
                        style={{ textDecoration: "none", color: "white" }}
                      >
                        Home
                      </Link>
                    </Nav.Link>

                    <NavDropdown
                      className="px-3 fs-5"
                      title="Services"
                      id={`offcanvasNavbarDropdown-expand-${expand}`}
                    >
                      <NavDropdown.Item href="/services/webdevelopment">
                        <Link
                          to={"/services/webdevelopment"}
                          style={{ textDecoration: "none", color: "white" }}
                        >
                          Web Development
                        </Link>
                      </NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item href="/services/ecommerce">
                        <Link
                          to={"/services/ecommerce"}
                          style={{ textDecoration: "none", color: "white" }}
                        >
                          Ecommerece Development
                        </Link>
                      </NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item href="/services/mobileapp">
                        <Link
                          to={"/services/mobileapp"}
                          style={{ textDecoration: "none", color: "white" }}
                        >
                          Mobile APP Development
                        </Link>
                      </NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item href="/services/software">
                        <Link
                          to={"/services/software"}
                          style={{ textDecoration: "none", color: "white" }}
                        >
                          Software Product Development
                        </Link>
                      </NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item href="/services/testing">
                        <Link
                          to={"/services/testing"}
                          style={{ textDecoration: "none", color: "white" }}
                        >
                          Software Testing & QA
                        </Link>
                      </NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item href="/services/uidesign">
                        <Link
                          to={"/services/uidesign"}
                          style={{ textDecoration: "none", color: "white" }}
                        >
                          UI UX Design
                        </Link>
                      </NavDropdown.Item>
                    </NavDropdown>
                    <Nav.Link className="px-3 fs-5" href="/technologies">
                      <Link
                        to={"/technologies"}
                        style={{ textDecoration: "none", color: "white" }}
                      >
                        Technologies
                      </Link>
                    </Nav.Link>
                    <Nav.Link className="px-3 fs-5" href="/industry">
                      <Link
                        to={"/industry"}
                        style={{ textDecoration: "none", color: "white" }}
                      >
                        Industry
                      </Link>{" "}
                    </Nav.Link>
                    {/* <Nav.Link href="/portfolio"> Portfolio</Nav.Link> */}
                    <NavDropdown
                      className="px-3 fs-5"
                      title="Our Company"
                      id={`offcanvasNavbarDropdown-expand-${expand}`}
                    >
                      <NavDropdown.Item href="/aboutus">
                        <Link
                          to={"/aboutus"}
                          style={{ textDecoration: "none", color: "white" }}
                        >
                          {" "}
                          About Us
                        </Link>
                      </NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item href="/leadership">
                        <Link
                          to={"/leadership"}
                          style={{ textDecoration: "none", color: "white" }}
                        >
                          Leadership
                        </Link>
                      </NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item href="/careers">
                        <Link
                          to={"/careers"}
                          style={{ textDecoration: "none", color: "white" }}
                        >
                          Careers
                        </Link>
                      </NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item href="/contactus">
                        <Link
                          to={"/contactus"}
                          style={{ textDecoration: "none", color: "white" }}
                        >
                          Contact Us
                        </Link>
                      </NavDropdown.Item>
                    </NavDropdown>
                  </Nav>

                  <Nav.Link
                    eventKey={2}
                    href="https://wa.me/+918341933684"
                    target="_blank"
                    className="ps-5"
                  >
                    <Button
                      variant="outline-light"
                      className="rounded-5 fs-6 px-4"
                    >
                      Let's Talk
                    </Button>
                  </Nav.Link>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Container>
          </Navbar>
        ))}
      </div>
    </section>
  );
};

export default Header;
