import React from "react";
import Homebanner from "../Components/homeBanner/Homebanner";

const Portfolio = () => {
  return (
    <div>
      <Homebanner />
    </div>
  );
};

export default Portfolio;
