import React from "react";
import "../Uioffers/Uioffers.css";
import Weoffer from "../Weoffer/Weoffer";
import softoffers1 from "../Assets/softoffer1.png";
import softoffers2 from "../Assets/softoffer2.png";
import softoffers3 from "../Assets/softoffer3.png";
import softoffers4 from "../Assets/softoffer4.png";
import softoffers5 from "../Assets/softoffer5.png";
import softoffers6 from "../Assets/softoffer6.png";

const softDevOffersData = [
  {
    id: 1,
    image: softoffers1,
    heading: "Initial Product Development",
    description:
      "From concept to launch, we specialize in Minimum Viable Product (MVP) development, ensuring rapid and cost-effective delivery of your product idea, allowing you to validate market demand and gather user feedback for future iterations.  ",
  },
  {
    id: 2,
    image: softoffers2,
    heading: "Architectural Design",
    description:
      "Our expert team designs robust and scalable software architectures, laying the foundation for efficient and sustainable product development, ensuring seamless integration, performance optimization, and future-proofing your technology stack. ",
  },
  {
    id: 3,
    image: softoffers3,
    heading: "User-Centric Design Services",
    description:
      "Elevate user experiences with our comprehensive UI/UX design services, crafting intuitive and visually appealing interfaces that engage users and drive adoption, ultimately enhancing customer satisfaction and retention. ",
  },
  {
    id: 4,
    image: softoffers4,
    heading: "Rigorous Testing and Quality Assurance",
    description:
      "Prioritize product reliability and performance with our thorough software testing and quality assurance processes, identifying and resolving issues early in the development lifecycle, ensuring a seamless and bug-free user experience.  ",
  },
  {
    id: 5,
    image: softoffers5,
    heading: "Ongoing Maintenance and Support",
    description:
      "Ensure the longevity and stability of your product with our dedicated maintenance and support services, providing timely updates, troubleshooting, and enhancements to meet evolving business needs and technological advancements. ",
  },
  {
    id: 6,
    image: softoffers6,
    heading: "Seamless Product and API Development",
    description:
      "Leverage our expertise in product and API development to create innovative solutions that integrate seamlessly with existing systems, enabling interoperability, data exchange, and enhanced functionality for your business ecosystem.  ",
  },
];

const Softdevoffers = () => {
  return (
    <section className="bg-light pb-5">
      <div className="pt-5 text-center">
        <h3>Exceptional Tailored Software Product Development Services</h3>
        <p className="px-5">
          Experience flawless custom software product development tailored to
          your unique needs. Our team specializes in crafting bespoke solutions
          that align perfectly with your business objectives. From
          conceptualization to deployment, we ensure every aspect of your
          software product meets the highest standards of quality and
          innovation. Trust us to deliver a solution that not only meets but
          exceeds your expectations, driving success for your organization.
        </p>
      </div>
      <div className=" uioffer-container">
        {softDevOffersData.map((offers, index) => (
          <Weoffer
            key={index}
            image={offers.image}
            heading={offers.heading}
            description={offers.description}
          />
        ))}
      </div>
    </section>
  );
};

export default Softdevoffers;
