import React from "react";
import Webdevbanner from "../Components/WebDevBanner/Webdevbanner";
import Webdevbody from "../Components/WebdevBody/Webdevbody";
import Webapp from "../Components/WebAppTypes/Webapp";
import Homecontact from "../Components/Homecontact/Homecontact";
import Webdevskills from "../Components/Webdevskills/Webdevskills";

const Webdevelop = () => {
  return (
    <div>
      <Webdevbanner />
      <Webdevbody />

      <Webapp />
      <Homecontact />
      <Webdevskills />
    </div>
  );
};

export default Webdevelop;
