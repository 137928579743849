import React from "react";
import "../Uioffers/Uioffers.css";
import Weoffer from "../Weoffer/Weoffer";
import image1 from "../Assets/ecombody1.png";
import image2 from "../Assets/ecombody2.png";
import image3 from "../Assets/ecombody3.png";
import image4 from "../Assets/ecombody4.png";
import image5 from "../Assets/ecombody5.png";
import image6 from "../Assets/ecombody6.png";
import image7 from "../Assets/frontend (2).png";
import image8 from "../Assets/backendweb.png";
import image9 from "../Assets/ecombody9.png";
import image10 from "../Assets/ecombody10.png";

const ecomSkillData = [
  {
    id: 1,
    image: image1,
    heading: "Online Shopping App Creation",
    description:
      "Transform your business into a digital marketplace with our bespoke online shopping app creation services. From conceptualization to deployment, we leverage cutting-edge technologies to craft intuitive and feature-rich mobile applications tailored to your unique business requirements. ",
  },
  {
    id: 2,
    image: image2,
    heading: "Expert Advice for Ecommerce Apps",
    description:
      "Gain invaluable insights and guidance from our seasoned experts in eCommerce app development. With years of industry experience, we provide strategic advice and recommendations to optimize your eCommerce apps for enhanced user engagement, conversion rates, and overall performance. ",
  },
  {
    id: 3,
    image: image3,
    heading: "Tailored Online Store Development",
    description:
      "Elevate your online presence with our customized online store development solutions. We meticulously design and develop eCommerce platforms that reflect your brand identity while offering seamless navigation, captivating user experiences, and robust functionality to drive sales and foster customer loyalty.  ",
  },
  {
    id: 4,
    image: image4,
    heading: "Ecommerce Platform Transfer",
    description:
      "Seamlessly migrate your eCommerce platform with our hassle-free transfer services. Whether upgrading to a new platform or transitioning between hosting providers, our experienced team ensures a smooth and efficient transfer process, minimizing downtime and preserving data integrity for uninterrupted business operations.",
  },
  {
    id: 5,
    image: image5,
    heading: "Online Payment Solutions",
    description:
      "Streamline your online transactions with our comprehensive online payment solutions. From integrating secure payment gateways to implementing advanced fraud detection mechanisms, we empower your eCommerce business with seamless, reliable, and secure payment processing capabilities to enhance customer trust and satisfaction. ",
  },
  {
    id: 6,
    image: image6,
    heading: "Ecommerce Apps Assistance",
    description:
      "Receive dedicated support and assistance for your eCommerce apps from our proficient team. Whether troubleshooting technical issues, optimizing performance, or implementing new features, we provide prompt and effective assistance to ensure your eCommerce apps operate flawlessly and deliver exceptional user experiences. ",
  },
  {
    id: 6,
    image: image7,
    heading: "Advanced Frontend Solutions for Ecommerce Apps:",
    description:
      "Craft captivating user interfaces and seamless navigation for your ecommerce apps. Our front-end development expertise ensures engaging and intuitive user experiences that drive conversions and enhance brand loyalty. ",
  },
  {
    id: 6,
    image: image8,
    heading: "Robust Backend Solutions for Ecommerce Apps:",
    description:
      "Build robust and scalable backend systems to power your ecommerce apps. Our backend development solutions are tailored to meet your business needs, ensuring efficient data management, security, and performance optimization. ",
  },
  {
    id: 6,
    image: image9,
    heading: "Seamless Integration of Third-party APIs",
    description:
      "Enhance the functionality of your ecommerce apps with seamless integration of third-party APIs. From payment gateways to shipping services, we integrate external APIs to streamline operations and provide enhanced features for your customers. ",
  },
  {
    id: 6,
    image: image10,
    heading: "Smooth Ecommerce Migration Services",
    description:
      "Seamlessly transfer your ecommerce platform with our expert migration services. Whether upgrading to a new platform or moving between hosting providers, we ensure a smooth transition while preserving data integrity and minimizing downtime for your business.",
  },
];

const Ecombody = () => {
  return (
    <section className="py-5 bg-light">
      <div className="pt-5 text-center">
        <h3>
          We provide innovative and customized e-commerce solutions for your
          business.
        </h3>
        <p className="px-5">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque minima
          odio eius, maxime quibusdam corporis perferendis autem eos illum
          expedita sunt alias assumenda aspernatur nisi commodi fuga, est labore
          ab.
        </p>
      </div>
      <div className=" uioffer-container">
        {ecomSkillData.map((offers, index) => (
          <Weoffer
            key={index}
            image={offers.image}
            heading={offers.heading}
            description={offers.description}
          />
        ))}
      </div>
    </section>
  );
};

export default Ecombody;
