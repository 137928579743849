import React from "react";
import "../Industry/Industrybanner.css";
import Button from "react-bootstrap/Button";

const Industrybanner = () => {
  return (
    <div>
      <div className="industry-container container-fluid"></div>
      <div className="text-center industry-content text-gray">
        <h1 className=""> Industries We Serve</h1>
        <p className=" pt-3 text-center">
          We create custom software solutions for any industry. Whether you need
          a web app, a mobile app, or a desktop app, we can build it for you. We
          use the latest technologies and best practices to deliver high-quality
          software that meets your specific needs and goals. No matter what
          industry you are in, we can help you grow your business with software.
        </p>
        <div className="text-center mt-4">
          <a
            href="https://wa.me/+919618800449"
            rel="noreferrer"
            target="_blank"
          >
            <Button variant="light" className="rounded-5   px-4 py-2">
              Let's Talk
            </Button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Industrybanner;
