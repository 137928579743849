import React from "react";
import "../Uioffers/Uioffers.css";
import Weoffer from "../Weoffer/Weoffer";
import mobilehealth from "../Assets/mobilehealth.png";
import mobileeducation from "../Assets/mobileeducation.png";
import mobileentertain from "../Assets/mobileentertain.png";
import mobilehotels from "../Assets/mobilehotels.png";
import mobilemarket from "../Assets/mobilemarket.png";
import mobilebank from "../Assets/mobileappbank.png";

const mobileOffersData = [
  {
    id: 1,
    image: mobileentertain,
    heading: "Entertainment",
    description:
      "Enjoy streaming TV shows, web series, and music with download capabilities, personalized recommendations, and customizable playlists.  ",
  },
  {
    id: 2,
    image: mobilehotels,
    heading: "Tourism and Hotels",
    description:
      "Enhance your vacation experience by extending services to enable viewing, booking, and payment directly from your mobile phone. Enjoy a superior holiday experience with tailored packages designed to cater to your preferences. ",
  },
  {
    id: 3,
    image: mobilemarket,
    heading: "Online Marketplace",
    description:
      "Efficiently market products and cultivate relationships with both B2C and B2B customers. Implement multiple gateways to optimize retail operations. ",
  },
  {
    id: 4,
    image: mobileeducation,
    heading: "Educational Institutes",
    description:
      "Enable students, teachers, and staff to efficiently conduct daily tasks and oversee essential data through a unified dashboard.  ",
  },
  {
    id: 5,
    image: mobilehealth,
    heading: "Health and Pharmacy",
    description:
      "An application for scheduling appointments, accessing medical consultations, and tracking real-time data from IoT-enabled healthcare devices. ",
  },
  {
    id: 6,
    image: mobilebank,
    heading: "Banking and Finance",
    description:
      "Easily send money, handle finances, review account specifics, and securely apply for cards and loans—all at the tap of a button. ",
  },
];

export const Mobileoffers = () => {
  return (
    <section className="bg-light pb-5">
      <div className="pt-5 text-center">
        <h3>
          Tailored Mobile App Development Solutions for a Variety of Businesses
        </h3>
        <p className="px-5">
          As a mobile app development service provider, we aid businesses across
          all industries and scales in reaching their objectives and enhancing
          daily operations with dependable and adaptable applications.
        </p>
      </div>
      <div className=" uioffer-container">
        {mobileOffersData.map((offers, index) => (
          <Weoffer
            key={index}
            image={offers.image}
            heading={offers.heading}
            description={offers.description}
          />
        ))}
      </div>
    </section>
  );
};
