import React from "react";
import Mobilebanner from "../Components/Mobilebanner/Mobilebanner";
import { Mobileoffers } from "../Components/Mobileoffers/Mobileoffers";
import Webdevbody from "../Components/WebdevBody/Webdevbody";
import Mobileskills from "../Components/Mobileskills/Mobileskills";
import Homecontact from "../Components/Homecontact/Homecontact";
import Mobileapptech from "../Components/MobileAppTech/Mobileapptech";

const Mobileapp = () => {
  return (
    <div>
      <Mobilebanner />
      <Mobileoffers />
      <Webdevbody />
      <Homecontact />
      <Mobileskills />
      <Mobileapptech />
    </div>
  );
};

export default Mobileapp;
