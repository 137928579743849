import React from "react";
import "../Pages/Pages.css";

import banner from "../Components/Assets/careerbanner.jpg";
import Banner from "../Components/Banner/Banner";
import Careerbody from "../Components/Careerbody/Careerbody";

const Careers = () => {
  return (
    <div>
      <Banner
        img={banner}
        title="Explore Exciting Career Opportunities"
        description="
Join a pioneering industry leader and thrive in a dynamic environment where learning, support, and empowerment propel your aspirations forward with equal opportunity."
      />
      <Careerbody />
    </div>
  );
};

export default Careers;
