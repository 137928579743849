import React from "react";
import "../Heading/Heading.css";

const Heading = ({ heading, description }) => {
  return (
    <div className="heading-container">
      <h3>{heading}</h3>
      <p>{description}</p>
    </div>
  );
};

export default Heading;
