import React from "react";
import Techbanner from "../Components/Techbanner/Techbanner";
import Techbody from "../Components/Techbody/Techbody";

const Technologies = () => {
  return (
    <div>
      <Techbanner />
      <Techbody />
    </div>
  );
};

export default Technologies;
