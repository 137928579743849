import React from "react";
import Industrybanner from "../Components/Industry/Industrybanner";
import Industrybody from "../Components/Industrybody/Industrybody";

const Industry = () => {
  return (
    <div>
      <Industrybanner />
      <Industrybody />
    </div>
  );
};

export default Industry;
