import React from "react";
import "../Uiskills/Uiskills.css";

import Ourskills from "../Ourskills/Ourskills";
import image1 from "../Assets/ecomskill1.png";
import image2 from "../Assets/ecomskill2.png";
import image3 from "../Assets/ecomskill3.png";
import image4 from "../Assets/ecomskill4.png";
import image5 from "../Assets/ecomskill5.png";
import image6 from "../Assets/ecomskill6.png";
import image7 from "../Assets/ecomskill7.png";
import image8 from "../Assets/ecomskill8.png";

const ecomSkillData = [
  {
    id: 1,
    image: image1,
    heading: "Innovative Website Designs",
    description:
      "Elevate your online presence with our imaginative web designs. We blend creativity with functionality to deliver visually stunning and user-friendly websites that captivate audiences and leave a lasting impression. ",
  },
  {
    id: 2,
    image: image2,
    heading: "Establish Your Brand Identity",
    description:
      "Forge a strong brand identity with our strategic branding solutions. From logo design to brand messaging, we help businesses stand out in a competitive market and establish a memorable presence that resonates with their target audience.",
  },
  {
    id: 3,
    image: image3,
    heading: "SEO Optimized Web Solutions",
    description:
      "Drive organic traffic and improve search engine visibility with our SEO-friendly web solutions. We optimize every aspect of your website for maximum performance, ensuring higher rankings, increased traffic, and greater online exposure for your business. ",
  },
  {
    id: 4,
    image: image4,
    heading: "Transparent Pricing Structures",
    description:
      "Experience transparent and competitive pricing with our cost-effective solutions. We offer expedient pricing models tailored to your budget and requirements, ensuring affordability without compromising on quality or service. ",
  },
  {
    id: 5,
    image: image5,
    heading: "Customer-Centric Approach",
    description:
      "Put your customers first with our customer-centric approach. We prioritize your needs, preferences, and feedback to deliver personalized solutions that exceed expectations and drive satisfaction. ",
  },
  {
    id: 6,
    image: image6,
    heading: "Cutting-Edge CMS Solutions",
    description:
      "Stay ahead of the curve with our future-ready CMS solutions. We leverage advanced content management systems to empower businesses with flexible, scalable, and intuitive platforms that adapt to evolving industry trends and technology advancements.",
  },
  {
    id: 6,
    image: image7,
    heading: "User-Focused UI Design",

    description:
      "Enhance user experience and engagement with Hick's Law-based UI design principles. We simplify decision-making processes and improve navigation efficiency to create seamless and intuitive user interfaces that drive conversions and satisfaction. ",
  },
  {
    id: 6,
    image: image8,
    heading: "Effortless Software Deployment",

    description:
      "Simplify the software deployment process with our seamless rollout solutions. We streamline every step of the deployment lifecycle, from planning and testing to implementation and monitoring, to ensure a smooth and hassle-free experience for your business. ",
  },
];

const Ecomskills = () => {
  return (
    <section className="py-5 ">
      <div className="pt-2 text-center ">
        <h3>Why Choose Us for Outstanding Ecommerce Development Services</h3>
        <p className="px-5">
          Discover unparalleled expertise and dedication with our ecommerce
          development services. We combine innovation, reliability, and
          customer-centricity to deliver exceptional solutions tailored to your
          business needs. Trust us to transform your vision into a thriving
          online platform that stands out in the digital landscape
        </p>
      </div>
      <div className=" uiskills-container pt-3">
        {ecomSkillData.map((skills, index) => (
          <Ourskills
            key={index}
            image={skills.image}
            heading={skills.heading}
            description={skills.description}
          />
        ))}
      </div>
    </section>
  );
};

export default Ecomskills;
