import React from "react";
import Ourskills from "../Ourskills/Ourskills";
import image1 from "../Assets/webskill1.png";
import image2 from "../Assets/webskill2.png";
import image3 from "../Assets/webskill3.png";
import image4 from "../Assets/webskill4.png";
import image5 from "../Assets/webskill5.png";
import image6 from "../Assets/webskill6.png";
import image7 from "../Assets/webskill7.png";
import mobileskill1 from "../Assets/mobileskill1.png";
import mobileskill2 from "../Assets/mobileskill2.png";
import mobileskill3 from "../Assets/mobileskill3.png";
import mobileskill4 from "../Assets/mobileskill4.png";
const aboutSkillData = [
  {
    id: 1,
    image: image1,
    heading: "Adaptable Engagement Approach",
    description:
      "Our engagement model is tailored to suit your unique needs, ensuring flexibility and seamless collaboration throughout the project lifecycle. Whether it's Agile, Waterfall, or a hybrid approach, we adapt to your preferences to drive optimal results. ",
  },
  {
    id: 2,
    image: image2,
    heading: "Consistent Delivery Excellence",
    description:
      "We maintain a uniform standard of delivery excellence across all projects, adhering to industry best practices and quality benchmarks. Our commitment to consistency ensures that every deliverable meets or exceeds expectations, regardless of project size or complexity. ",
  },
  {
    id: 3,
    image: image3,
    heading: "Scalable Capacity Fulfillment",
    description:
      "We are equipped to meet your demand at scale, whether you require additional resources for a short-term project or ongoing support for long-term initiatives. Our scalable approach ensures that we can ramp up or down based on your evolving needs, without compromising on quality or efficiency.  ",
  },
  {
    id: 4,
    image: image4,
    heading: "Proven Expertise and Insight",
    description:
      "With years of experience in the industry, our team brings valuable insights and expertise to every project. We leverage our deep domain knowledge and technical proficiency to drive innovation and deliver solutions that drive tangible business value. ",
  },
  {
    id: 5,
    image: image5,
    heading: "Client-Centered Collaboration",
    description:
      "Our client-centric approach places your needs and objectives at the forefront of everything we do. We prioritize open communication, transparency, and collaboration to ensure that we fully understand your requirements and deliver solutions that exceed your expectations.  ",
  },
  {
    id: 6,
    image: image6,
    heading: "Seamless Communication Channels",
    description:
      "We prioritize effective communication channels to keep you informed and engaged throughout the project lifecycle. From regular status updates to proactive problem-solving, we ensure that you're always in the loop and empowered to make informed decisions. ",
  },
  {
    id: 6,
    image: image7,
    heading: "Comprehensive Support and Maintenance",

    description:
      "Our commitment to your success extends beyond project completion. We offer comprehensive support and maintenance services to ensure that your solutions remain robust, secure, and optimized for peak performance over the long term. ",
  },
  {
    id: 1,
    image: mobileskill1,
    heading: "Native Mobile App Development",
    description:
      "Craft seamless and high-performance mobile applications tailored specifically for iOS and Android platforms, leveraging the unique features of each operating system for optimal user experience. ",
  },
  {
    id: 2,
    image: mobileskill2,
    heading: "Platform-Agnostic App Development",
    description:
      "Build versatile applications that function seamlessly across various devices and operating systems, ensuring consistent performance and user satisfaction regardless of the platform.  ",
  },
  {
    id: 3,
    image: mobileskill3,
    heading: "Progressive Web App Development",
    description:
      "Create web applications with advanced features that offer an app-like experience, including offline functionality, push notifications, and fast loading times, enhancing user engagement and accessibility. ",
  },
  {
    id: 4,
    image: mobileskill4,
    heading: "Tailored Software Solutions for Wearables",
    description:
      "Develop customized software applications optimized for wearable devices, enabling users to seamlessly integrate wearable technology into their daily lives for enhanced productivity and convenience.  ",
  },
];

const Aboutskills = () => {
  return (
    <section className="py-5 bg-light">
      <div className="pt-2 text-center ">
        <h3>Our Skills</h3>
        <p className="px-5">
          Discover unparalleled expertise and dedication with our ecommerce
          development services. We combine innovation, reliability, and
          customer-centricity to deliver exceptional solutions tailored to your
          business needs. Trust us to transform your vision into a thriving
          online platform that stands out in the digital landscape
        </p>
      </div>
      <div className=" uiskills-container pt-3">
        {aboutSkillData.map((skills, index) => (
          <Ourskills
            key={index}
            image={skills.image}
            heading={skills.heading}
            description={skills.description}
          />
        ))}
      </div>
    </section>
  );
};

export default Aboutskills;
