import React from "react";
import "../Pricing/Pricing.css";
import Heading from "../Heading/Heading";
import { Container, Row, Col } from "react-bootstrap";

const Pricing = () => {
  return (
    <section className="bg-dark pt-5">
      <div className="text-center text-white">
        <Heading
          heading="Software Services Pricing"
          description="  Our software services pricing is tailored to your unique needs. We offer flexible pricing models to accommodate projects of all sizes and complexities. Whether you're a startup or an enterprise, our transparent pricing ensures you get the best value for your investment. Contact us today for a personalized quote."
        />
        <Container className=" pricing-container pb-5 text-black">
          <Row>
            <Col xs={12} md={4} className=" pricing-container-col">
            <div style={{ backgroundColor: "lightblue", height: "200px" }}>
                <h1 className="pt-3 Price-1">$15</h1>
                <p style={{ fontFamily  : "Times New Roman" }}>Per Hourly(USD)</p>
                <p className="year-1 DUR-1">1-2 Years</p>
              </div>
            </Col>
            <Col xs={12} md={4} className=" pricing-container-col">
              {/* Content for second column */}
              <div style={{ backgroundColor: "lightgreen", height: "200px" }}>
                <h1 className="pt-3 Price-1">$1500</h1>
                <p className="year-1 DUR-1">Monthly(USD)</p>
                <p className="year-1 DUR-1">3-6 Years</p>
              </div>
            </Col>
            <Col xs={12} md={4} className=" pricing-container-col">
              {/* Content for third column */}
              <div style={{ backgroundColor: "lightcoral", height: "200px" }}>
                <h1 className="pt-3 Price-1" >$8000</h1>
                <p className="year-1 DUR-1">Monthly(USD)</p>
                <p className="year-1 DUR-1">6+ Years</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
};

export default Pricing;
