import React from "react";
// import Homebanner from "../Components/homeBanner/Homebanner";
import Homeimage from "../Components/Homeimage/Homeimage";
import Hometech from "../Components/Hometech/Hometech";
import Homeservices from "../Components/Homeservices/Homeservices";
import Homeindustry from "../Components/Homeindustry/Homeindustry";
import Homecontact from "../Components/Homecontact/Homecontact";

import Homeabout from "../Components/Homeabout/Homeabout";
import Homeworkflow from "../Components/Homeworkflow/Homeworkflow";
import Pricing from "../Components/Pricing/Pricing";

const Home = () => {
  return (
    <div>
      <Homeimage />
      {/* <Homebanner /> */}
      <Hometech />
      <Homeservices />

      <Homeindustry />
      <Homecontact />
      <Homeworkflow />
      <Pricing />
      <Homeabout />
    </div>
  );
};

export default Home;
