import React from "react";
import Heading from "../Heading/Heading";
import { Container, Row, Col } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import suresh from "../Assets/suresh.jpeg";
import swathi from "../Assets/swathi.jpeg";
import image1 from "../Assets/photomen.png";

const Leadbody = () => {
  return (
    <section className="pt-5 bg-light">
      <div>
        <Heading
          heading="Discover the Minds Behind Cornerstone Technologies  Success."
          description="Founded in 2015, Cornerstone stands at the forefront of revolutionizing the software solutions landscape, delivering tailored software and application solutions. As a premier software development outsourcing company, our commitment to excellence shines through in our offerings of software outsourcing, strategic consultation, maintenance, and application modernization services. Through enduring partnerships with our founders and seasoned leaders, Cornerstone has propelled countless enterprises to success worldwide."
        />
      </div>
      <Container className="py-3">
        <Row>
          <Col sm={10} md={10} lg={4}>
            <Card style={{ width: "100%", height: "860px" }}>
              <Card.Img id="img" variant="top" src={swathi} />
              <Card.Body>
                <Card.Title>Swathi Nihitha M</Card.Title>
                <span className="text-danger">Founder and CEO</span>

                <Card.Text className="pt-3">
                  Swathi Nihitha M, the Founder & CEO of Cornerstone Technologies,
                  With a robust background spanning over a decade in commercial 
                  software development and bolstered by numerous programming 
                  certifications, Swathi is driven by apassion for leveraging 
                  technology to its fullest potential.Her expertise lies in crafting 
                  tailored management solutions that seamlessly align with user 
                  requirements, turning vision into reality.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={10} md={10} lg={4}>
            <Card style={{ width: "100%", height: "860px" }}>
              <Card.Img id="img" variant="top" src={suresh} />
              <Card.Body>
                <Card.Title>SURESH KUMAR, M</Card.Title>
                <span className="text-danger">Chief Technical Officer</span>
                <Card.Text>
                  SURESH KUMAR serves as the Chief Technical Officer (CTO) at
                  Cornerstone Technologies. Brings over 15 years of programming 
                  experience to the table.With a relentless passion for learning
                  and staying abreast of emerging technologies, he serves as the 
                  driving force behind the company's vision. SURESH is renowned for
                  his strategic acumen, responsible for spearheading business growth 
                  and charting future.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col id="imghide" sm={12} md={6} lg={4}>
            <Card style={{ width: "100%", height: "750px" }}>
              <Card.Img variant="top" src={image1} />
              <Card.Body>
                <Card.Title>Card Title</Card.Title>
                <Card.Text>
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Leadbody;
