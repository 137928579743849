import React from "react";
import "../Uiskills/Uiskills.css";

import Ourskills from "../Ourskills/Ourskills";
import image1 from "../Assets/webskill1.png";
import image2 from "../Assets/webskill2.png";
import image3 from "../Assets/webskill3.png";
import image4 from "../Assets/webskill4.png";
import image5 from "../Assets/webskill5.png";
import image6 from "../Assets/webskill6.png";
import image7 from "../Assets/webskill7.png";

const webDevSkillData = [
  {
    id: 1,
    image: image1,
    heading: "Adaptable Engagement Approach",
    description:
      "Our engagement model is tailored to suit your unique needs, ensuring flexibility and seamless collaboration throughout the project lifecycle. Whether it's Agile, Waterfall, or a hybrid approach, we adapt to your preferences to drive optimal results. ",
  },
  {
    id: 2,
    image: image2,
    heading: "Consistent Delivery Excellence",
    description:
      "We maintain a uniform standard of delivery excellence across all projects, adhering to industry best practices and quality benchmarks. Our commitment to consistency ensures that every deliverable meets or exceeds expectations, regardless of project size or complexity. ",
  },
  {
    id: 3,
    image: image3,
    heading: "Scalable Capacity Fulfillment",
    description:
      "We are equipped to meet your demand at scale, whether you require additional resources for a short-term project or ongoing support for long-term initiatives. Our scalable approach ensures that we can ramp up or down based on your evolving needs, without compromising on quality or efficiency.  ",
  },
  {
    id: 4,
    image: image4,
    heading: "Proven Expertise and Insight",
    description:
      "With years of experience in the industry, our team brings valuable insights and expertise to every project. We leverage our deep domain knowledge and technical proficiency to drive innovation and deliver solutions that drive tangible business value. ",
  },
  {
    id: 5,
    image: image5,
    heading: "Client-Centered Collaboration",
    description:
      "Our client-centric approach places your needs and objectives at the forefront of everything we do. We prioritize open communication, transparency, and collaboration to ensure that we fully understand your requirements and deliver solutions that exceed your expectations.  ",
  },
  {
    id: 6,
    image: image6,
    heading: "Seamless Communication Channels",
    description:
      "We prioritize effective communication channels to keep you informed and engaged throughout the project lifecycle. From regular status updates to proactive problem-solving, we ensure that you're always in the loop and empowered to make informed decisions. ",
  },
  {
    id: 6,
    image: image7,
    heading: "Comprehensive Support and Maintenance",

    description:
      "Our commitment to your success extends beyond project completion. We offer comprehensive support and maintenance services to ensure that your solutions remain robust, secure, and optimized for peak performance over the long term. ",
  },
];

const Webdevskills = () => {
  return (
    <section className="py-5 ">
      <div className="pt-2 text-center ">
        <h3>Proficient Capabilities and Specializations</h3>
        <p className="px-5">
          Our suite of web development skills encompasses a wide array of
          technologies and expertise. From frontend to backend, UI/UX design to
          database management, we offer holistic solutions tailored to your
          project's needs. Trust us to deliver robust, scalable, and
          user-centric web applications.
        </p>
      </div>
      <div className=" uiskills-container pt-3">
        {webDevSkillData.map((skills, index) => (
          <Ourskills
            key={index}
            image={skills.image}
            heading={skills.heading}
            description={skills.description}
          />
        ))}
      </div>
    </section>
  );
};

export default Webdevskills;
