import React from "react";
import "../Mobilebanner/Mobilebanner.css";
import mobilebannerimg from "../Assets/mobilebanner.jpg";
import Button from "react-bootstrap/Button";

const Mobilebanner = () => {
  return (
    <section>
      <div className="mobilebanner-container">
        <img src={mobilebannerimg} alt="" />
        <div className="mobilebanner-container-content text-center text-white">
          <h1>Mobile App Development Services </h1>
          <p className="">
            Cornerstone stands out as the premier Mobile App Development
            Company, boasting over a decade of expertise and a stellar track
            record in delivering top-notch project management and versatile
            delivery models. Our mission is to craft cutting-edge mobile
            applications that empower businesses to enhance productivity and
            connect seamlessly with end-users. Backed by a team of seasoned and
            proficient mobile app developers, we specialize in meeting modern
            mobile app demands, be it native iOS, Android apps, or Hybrid apps.
          </p>
          <div className=" mobilebanner-button text-center p-3">
            <a
              href="https://wa.me/+919618800449"
              rel="noreferrer"
              target="_blank"
            >
              <Button variant="light" className="rounded-5   px-4 py-2">
                Let's Talk
              </Button>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Mobilebanner;
