import React from "react";
import Banner from "../Components/Banner/Banner";
import bannerimage from "../Components/Assets/leaderbanner.jpg";
import Leadbody from "../Components/Leadbody/Leadbody";

const LeaderShip = () => {
  return (
    <div>
      <Banner
      
        img={bannerimage}
        title={<span style={{ color: "black" }}>Our Team</span>}
        description={
          <span style={{ color: "black" }}>
            The Cornerstone development team truly stood out. Their deep
            understanding of your project's intricacies led to invaluable
            recommendations for improvement. Their unwavering responsiveness to
            your emails and calls, irrespective of the hour, was remarkable.
            Thanks to their dedication, you now have a robust business solution
            that has transformed your operations for the better.
          </span>
        }
      >
          </Banner>
      <Leadbody />
    </div>
  );
};

export default LeaderShip;
