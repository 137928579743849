import React from "react";
import "../ContactSkills/Contactskills.css";
import image1 from "../Assets/contactskill1.png";
import image2 from "../Assets/contactskill2.png";
import image3 from "../Assets/contactskill3.png";
import image4 from "../Assets/contactskill4.png";

const Contactskills = () => {
  return (
    <section className="bg-light py-3 ">
      <div className="text-center pt-3">
        <h3>Why Cornerstone</h3>
        <p>
          At Cornerstone, we ensure a seamless experience for clients seeking
          solutions. Reach out to us effortlessly, and let us design the perfect
          solution for you!
        </p>
      </div>

      <div className="container contactskills-container bg-white border border-1 mb-3">
        <div className="contactskills-container-image ps-3 ">
          <img src={image1} alt="" />
        </div>
        <div className="contactskills-container-content pt-3 ">
          <h5>Define Your Requirements</h5>
          <ul>
            <li>
              <b className="pe-2">Understand Your Goals:</b>
              We begin by comprehensively understanding your project objectives
              and desired outcomes.
            </li>
            <li>
              <b className="pe-2">Detailed Discussions:</b>
              Our team engages in thorough discussions to capture every
              essential requirement.
            </li>
            <li>
              <b className="pe-2">Prioritize Features: </b>
              We help you prioritize features, functionalities, and constraints.
            </li>
            <li>
              <b className="pe-2">Documenting Needs:</b>
              Clear documentation ensures alignment between your vision and our
              solution.
            </li>
          </ul>
        </div>
      </div>
      <div className="container contactskills-container bg-white  border border-1 mb-3">
        <div className="contactskills-container-image ps-3 ">
          <img src={image2} alt="" />
        </div>
        <div className="contactskills-container-content pt-3 ">
          <h5>Confidentiality Assured</h5>
          <ul>
            <li>
              <b className="pe-2">NDA Signing: </b>
              Our Non-Disclosure Agreement (NDA) safeguards your proprietary
              information.
            </li>
            <li>
              <b className="pe-2">Strict Confidentiality:</b>
              Rest assured that your data remains confidential throughout the
              process.
            </li>
            <li>
              <b className="pe-2">Legal Protection: </b>
              The NDA legally binds us to maintain secrecy about your project.
            </li>
            <li>
              <b className="pe-2">Trustworthy Partnership: </b>
              We value your trust and take confidentiality seriously.
            </li>
          </ul>
        </div>
      </div>
      <div className="container contactskills-container bg-white  border border-1 mb-3">
        <div className="contactskills-container-image ps-3 ">
          <img src={image4} alt="" />
        </div>
        <div className="contactskills-container-content pt-3 ">
          <h5>Thorough Evaluation</h5>
          <ul>
            <li>
              <b className="pe-2">In-Depth Analysis: </b>
              Our experts delve into the intricacies of your requirements.
            </li>
            <li>
              <b className="pe-2">Feasibility Assessment:</b>
              We evaluate technical feasibility, resource availability, and
              risks.
            </li>
            <li>
              <b className="pe-2">User-Centric Approach: </b>
              Understanding end-users ensures a solution that truly meets their
              needs.
            </li>
            <li>
              <b className="pe-2">Iterative Refinement: </b>
              We iterate and refine our understanding based on your feedback.
            </li>
          </ul>
        </div>
      </div>
      <div className="container contactskills-container bg-white  border border-1 mb-3">
        <div className="contactskills-container-image ps-3 ">
          <img src={image3} alt="" />
        </div>
        <div className="contactskills-container-content pt-3 ">
          <h5>Budget Estimation</h5>
          <ul>
            <li>
              <b className="pe-2">Transparent Breakdown: </b>
              We provide a detailed breakdown of costs, including development,
              testing, and maintenance.
            </li>
            <li>
              <b className="pe-2">Scope-Driven Estimates: </b>
              Our estimates align with the project scope and complexity.
            </li>
            <li>
              <b className="pe-2">No Hidden Costs: </b>
              You’ll know exactly what to expect—no surprises.
            </li>
            <li>
              <b className="pe-2">Flexible Options:</b>
              We explore cost-effective solutions without compromising quality.
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Contactskills;
