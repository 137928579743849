import React from "react";
import "../Contactbanner/Contactbanner.css";
import homecontactbanner from "../Assets/conatctbanner2.jpg";

const Contactbanner = () => {
  return (
    <section className="contactbanner-section">
      <div className="contactbanner-container">
        <img src={homecontactbanner} alt="" />
        {/* <div className="contactbanner-container-content text-center ">
          <h5>Make Your Dream App Come True</h5>
          <h1>Cornerstone Technologies</h1>
          <p className="px-5 pb-3">
            The Cornerstone development team truly stood out. Their deep
            understanding of your project's intricacies led to invaluable
            recommendations for improvement. Their unwavering responsiveness to
            your emails and calls, irrespective of the hour, was remarkable.
            Thanks to their dedication, you now have a robust business solution
            that has transformed your operations for the better.
          </p>
        </div> */}
      </div>
    </section>
  );
};

export default Contactbanner;
