import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import Footer from "./Components/Footer/Footer";
// import Headernav from "./Components/Headernav/Headernav";
import Technologies from "./Pages/Technologies";
import About from "./Pages/About";
import Industry from "./Pages/Industry";

import Webdevelop from "./Pages/Webdevelop";
import Ecommerce from "./Pages/Ecommerce";
import Uidesign from "./Pages/Uidesign";
import Testing from "./Pages/Testing";
import Contact from "./Pages/Contact";
import Mobileapp from "./Pages/Mobileapp";
import Sofrdev from "./Pages/Sofrdev";
import Header from "./Components/Header/Header";
import Portfolio from "./Pages/Portfolio";
import Careers from "./Pages/Careers";
import LeaderShip from "./Pages/LeaderShip";

function App() {
  return (
    <div>
      <BrowserRouter>
        {/* <Headernav /> */}
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/technologies" element={<Technologies />} />
          <Route path="/aboutus" element={<About />} />
          <Route path="/contactus" element={<Contact />} />
          <Route path="/industry" element={<Industry />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/leadership" element={<LeaderShip />} />
          <Route path="/services/webdevelopment" element={<Webdevelop />} />
          <Route path="/services/ecommerce" element={<Ecommerce />} />
          <Route path="/services/uidesign" element={<Uidesign />} />
          <Route path="/services/testing" element={<Testing />} />
          <Route path="/services/mobileapp" element={<Mobileapp />} />
          <Route path="/services/software" element={<Sofrdev />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
