import React from "react";
// import Ecommercebanners from "../Components/Ecommercebanner/Ecommercebanners";
import Hiring from "../Components/Hiring/Hiring";
import Ecombody from "../Components/Ecombody/Ecombody";
import Ecomskills from "../Components/EcomSkills/Ecomskills";
import Ecombanner from "../Components/Ecombanner/Ecombanner";

const Ecommerce = () => {
  return (
    <div>
      {/* <Ecommercebanners /> */}
      <Ecombanner />
      <Ecombody />
      <Ecomskills />
      <Hiring />
    </div>
  );
};

export default Ecommerce;
