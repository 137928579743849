import React from "react";
import "../Banner/Banner.css";
import Button from "react-bootstrap/Button";

const Banner = ({ img, title, description }) => {
  return (
    <section className="banner-section">
      <div className="banner-container">
        <img src={img} alt="" />
        <div className="banner-container-content text-center ">
          <h1>{title}</h1>
          <p className="px-5 pb-3">{description}</p>
          <a
            href="https://wa.me/+918341933684"
            rel="noreferrer"
            target="_blank"
          >
            <Button variant="outline-light" className="rounded-5 fs-6 px-4">
              Know More
            </Button>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Banner;
