import React from "react";
import "../Homeservices/Homeservices.css";
import { Container, Row, Col } from "react-bootstrap";
import webdev from "../Assets/webdev.png";
import uidesign from "../Assets/uxdesign.png";
import ecomdev from "../Assets/ecomdev.png";
import mobiledev from "../Assets/mobiledev.png";
import testingdev from "../Assets/testingdev.png";
import devteam from "../Assets/devteam.png";
import customdev from "../Assets/customdev.png";
import softdev from "../Assets/softdev.png";
import Heading from "../Heading/Heading";
import { Link } from "react-router-dom";

const Homeservices = () => {
  return (
    <section className="pt-5">
      <div className="">
        <Heading
          heading="Software Services We Serve"
          description=" We provide an extensive array of software solutions on a global scale,
          catering to various significant sectors such as B2B, B2C, D2C, and
          enterprise-level applications.."
        />
      </div>
      <Container>
        <Row className="gx-3">
          <Col
            className="homeservice-container-col text-center "
            sm={6}
            md={4}
            lg={3}
          >
            <Link className="homeservices-link" to={"/services/webdevelopment"}>
              <div className="homeservice-container-col-div border border-1 rounded">
                <img
                  className="homeservice-container-img"
                  src={webdev}
                  alt=""
                />
                <h5>Web Development</h5>
              </div>
            </Link>
          </Col>

          <Col
            className="homeservice-container-col text-center "
            sm={6}
            md={4}
            lg={3}
          >
            <Link className="homeservices-link" to={"/services/uidesign"}>
              <div className="homeservice-container-col-div border border-1 rounded">
                <img
                  className="homeservice-container-img "
                  src={uidesign}
                  alt=""
                />
                <h5>UI UX Design</h5>
              </div>
            </Link>
          </Col>
          <Col
            className="homeservice-container-col text-center "
            sm={6}
            md={4}
            lg={3}
          >
            <Link className="homeservices-link" to={"/services/ecommerce"}>
              <div className="homeservice-container-col-div border border-1 rounded">
                <img
                  className="homeservice-container-img"
                  src={ecomdev}
                  alt=""
                />
                <h5>Ecommerce Development</h5>
              </div>
            </Link>
          </Col>
          <Col
            className="homeservice-container-col text-center "
            sm={6}
            md={4}
            lg={3}
          >
            <Link className="homeservices-link" to={"/services/mobileapp"}>
              <div className="homeservice-container-col-div border border-1 rounded">
                <img
                  className="homeservice-container-img"
                  src={mobiledev}
                  alt=""
                />
                <h5>Mobile App Development</h5>
              </div>
            </Link>
          </Col>

          <Col
            className="homeservice-container-col text-center "
            sm={6}
            md={4}
            lg={3}
          >
            <Link className="homeservices-link" to={"/services/testing"}>
              <div className="homeservice-container-col-div border border-1 rounded">
                <img
                  className="homeservice-container-img"
                  src={testingdev}
                  alt=""
                />
                <h5>Software Testing & QA</h5>
              </div>
            </Link>
          </Col>

          <Col
            className="homeservice-container-col text-center "
            sm={6}
            md={4}
            lg={3}
          >
            <Link className="homeservices-link" to={"/"}>
              <div className="homeservice-container-col-div border border-1 rounded">
                <img
                  className="homeservice-container-img "
                  src={customdev}
                  alt=""
                />
                <h5>Custom Software Development</h5>
              </div>
            </Link>
          </Col>
          <Col
            className="homeservice-container-col text-center "
            sm={6}
            md={4}
            lg={3}
          >
            <Link className="homeservices-link" to={"/services/software"}>
              <div className="homeservice-container-col-div border border-1 rounded">
                <img
                  className="homeservice-container-img"
                  src={softdev}
                  alt=""
                />
                <h5>Software Product Development</h5>
              </div>
            </Link>
          </Col>
          <Col
            className="homeservice-container-col text-center "
            sm={6}
            md={4}
            lg={3}
          >
            <div className="homeservice-container-col-div border border-1 rounded">
              <img className="homeservice-container-img" src={devteam} alt="" />
              <h5>Development Team</h5>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Homeservices;
