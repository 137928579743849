import React from "react";
import "../Uiskills/Uiskills.css";
import uiskills1 from "../Assets/uiskills1.png";
import uiskills2 from "../Assets/uiskills2.png";
import uiskills3 from "../Assets/uiskills3.png";
import uiskills4 from "../Assets/uiskills4.png";
import uiskills5 from "../Assets/uiskills5.png";
import uiskills6 from "../Assets/uiskills6.png";
import Ourskills from "../Ourskills/Ourskills";

const uiSkillData = [
  {
    id: 1,
    image: uiskills1,
    heading: "Extensive Domain Knowledge",
    description:
      "Benefit from our diverse expertise spanning various industries and technologies, ensuring that we understand your unique requirements and deliver tailored solutions that meet your specific needs and objectives.  ",
  },
  {
    id: 2,
    image: uiskills2,
    heading: "Transparent Communication and Partnership",
    description:
      "Experience open and collaborative communication throughout the project lifecycle, as we work closely with you to understand your vision, address your concerns, and deliver results that exceed your expectations.  ",
  },
  {
    id: 3,
    image: uiskills3,
    heading: "Cutting-Edge UI/UX for Emerging Technologies",
    description:
      "Stay ahead of the curve with our innovative UI/UX design services tailored for emerging technologies, ensuring that your digital products captivate users and deliver seamless experiences on the latest platforms and devices. ",
  },
  {
    id: 4,
    image: uiskills4,
    heading: "Meticulous Attention to Detail",
    description:
      "Trust in our meticulous approach to design, as we pay close attention to every detail to ensure that your digital products not only look stunning but also function flawlessly, delivering a superior user experience.  ",
  },
  {
    id: 5,
    image: uiskills5,
    heading: "Continuous Iteration and Enhancement",
    description:
      "Embrace a culture of continuous improvement, as we constantly iterate and refine our designs based on user feedback and market trends, ensuring that your digital products evolve to meet changing needs and expectations. ",
  },
  {
    id: 6,
    image: uiskills6,
    heading: "Unwavering Focus on Client Satisfaction",
    description:
      "Your satisfaction is our top priority, and we go above and beyond to ensure that we meet and exceed your expectations at every step of the project, delivering solutions that drive success and leave a lasting impact on your business.  ",
  },
];

const Uiskills = () => {
  return (
    <section className="bg-dark py-3 ">
      <div className="pt-5 text-center text-white">
        <h2>Why Partner with Us for UI/UX Development?</h2>
        <p className="px-5">
          Select us as your UI/UX development company for unparalleled expertise
          and dedication to crafting captivating user experiences. Our seasoned
          team combines creativity and usability to deliver solutions that drive
          engagement, enhance satisfaction, and ultimately propel your business
          forward in the digital landscape.
        </p>
      </div>
      <div className=" uiskills-container pt-5">
        {uiSkillData.map((skills, index) => (
          <Ourskills
            key={index}
            image={skills.image}
            heading={skills.heading}
            description={skills.description}
          />
        ))}
      </div>
    </section>
  );
};

export default Uiskills;
