import React from "react";
import Contactbanner from "../Components/Contactbanner/Contactbanner";
import Contactform from "../Components/Contactform/Contactform";
import Contactskills from "../Components/ContactSkills/Contactskills";
import Hiring from "../Components/Hiring/Hiring";
import Homeworkflow from "../Components/Homeworkflow/Homeworkflow";

const Contact = () => {
  return (
    <div>
      <Contactbanner />
      <Contactskills />
      <Hiring />
      <Homeworkflow />

      <Contactform />
    </div>
  );
};

export default Contact;
