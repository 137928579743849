import React from "react";
import "../Footer/Footer.css";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FaXTwitter, FaFacebook } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
  const emailAddress = "info@cornerstoneaitechnologies.in";
  return (
    <>
      <footer className="footer bg-dark  ">
        <Container className="pt-3 footer-container">
          <Row className="mt-4 mb-4">
            <Col md={4} className="text-white">
              <h4>Cornerstone Technologies</h4>
              <div className="d-flex flex-row ">
              <Link to={"https://twitter.com/MyVillage47/moments"} target="_blank" rel="noopener noreferrer">
                <div className="p-2 fs-4 text-info">
                <FaXTwitter />
                  </div>
                </Link>
                
                <div className="p-2 fs-4 text-info">
                  <FaInstagram />
                </div>
                <Link to={"https://www.facebook.com/myhometown.darsi.52"} target="_blank" rel="noopener noreferrer">
                  <div className="p-2 fs-4 text-info">
                    <FaFacebook />
                  </div>
                </Link>
                <div className="p-2 fs-4 text-info ">
                <FaLinkedinIn/>
                </div>              
                <Link to={"https://wa.me/+918341933684?text=$"} target="_blank" rel="noopener noreferrer">
                <div className="p-2 fs-4 text-info">
                  <FaWhatsapp />
                </div>
                </Link>
              </div>
            </Col>
            <Col
              md={4}
              className=" foot-col2
              text-white"
            >
              <h4>Services We Offered</h4>
              <Link to={"/services/webdevelopment"}>
                <p className="m-0 p-0">Web Development</p>
              </Link>
              <Link to={"/services/ecommerce"}>
                <p className="m-0 p-0">Ecommerece Development</p>
              </Link>
              <Link to={"/services/mobileapp"}>
                <p className="m-0 p-0">Mobile App Development</p>
              </Link>
              <Link to={"/services/testing"}>
                <p className="m-0 p-0">Software Testing & QA</p>
              </Link>
              <Link to={"/services/uidesign"}>
                <p className="m-0 p-0">UI UX Design</p>
              </Link>
            </Col>
            <Col md={4} className="text-white">
              <h4>Contact Info</h4>
              <h5>Address</h5>
              <p className="m-0 p-0">Darsi India</p>
              <h5>Phone</h5>
              <p className="m-0 p-0">+91 83419 33684</p>
              <h5>Email</h5>

              <p className="m-0 p-0">
                <a href={`mailto:${emailAddress}`}>
                info@cornerstoneaitechnologies.in
                </a>
              </p>
            </Col>
            <hr className="text-info mt-2" />
            <Row className="justify-content-md-center m-0 p-0">
              <Col xs lg="4" className="text-white">
                <small>Copyright © 2024 Cornerstone Technologies</small>
              </Col>
            </Row>
          </Row>
        </Container>
      </footer>
    </>
  );
};

export default Footer;
