import React from "react";
import "../Homeimage/Homeimage.css";
import Button from "react-bootstrap/Button";

const Homeimage = () => {
  return (
    <div className="homeimage-container container-fluid">
      <div className="text-center homeimage-content">
        <h1 className="text-white homeimage-content-heading ">
          Cornerstone Technologies
        </h1>
        <h2 className="text-white p-2">"Affordable rates, exceptional service fueling your business growth"</h2>
        <div className="text-center mt-4">
          <a
            href="https://wa.me/+918341933684"
            rel="noreferrer"
            target="_blank"
          >
            <Button variant="outline-light" className="rounded-5 fs-6 px-4">
              Know More
            </Button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Homeimage;
