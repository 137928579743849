import React from "react";
import "../Uiskills/Uiskills.css";

import Ourskills from "../Ourskills/Ourskills";
import mobileskill1 from "../Assets/mobileskill1.png";
import mobileskill2 from "../Assets/mobileskill2.png";
import mobileskill3 from "../Assets/mobileskill3.png";
import mobileskill4 from "../Assets/mobileskill4.png";

const mobileSkillData = [
  {
    id: 1,
    image: mobileskill1,
    heading: "Native Mobile App Development",
    description:
      "Craft seamless and high-performance mobile applications tailored specifically for iOS and Android platforms, leveraging the unique features of each operating system for optimal user experience. ",
  },
  {
    id: 2,
    image: mobileskill2,
    heading: "Platform-Agnostic App Development",
    description:
      "Build versatile applications that function seamlessly across various devices and operating systems, ensuring consistent performance and user satisfaction regardless of the platform.  ",
  },
  {
    id: 3,
    image: mobileskill3,
    heading: "Progressive Web App Development",
    description:
      "Create web applications with advanced features that offer an app-like experience, including offline functionality, push notifications, and fast loading times, enhancing user engagement and accessibility. ",
  },
  {
    id: 4,
    image: mobileskill4,
    heading: "Tailored Software Solutions for Wearables",
    description:
      "Develop customized software applications optimized for wearable devices, enabling users to seamlessly integrate wearable technology into their daily lives for enhanced productivity and convenience.  ",
  },
];

const Mobileskills = () => {
  return (
    <section className="bg-light py-3 ">
      <div className="pt-5 text-center ">
        <h3>
          Premier Mobile App Development & Consulting Solutions by Cornerstone
        </h3>
        <p className="px-5">
          Cornerstone offers cutting-edge custom mobile app development
          solutions and consulting services worldwide. Our team of expert mobile
          app developers at Cornerstone specializes in delivering top-notch
          applications across diverse industries like healthcare, retail,
          ecommerce, and travel, ensuring the best value for your investment.
        </p>
      </div>
      <div className=" uiskills-container pt-5">
        {mobileSkillData.map((skills, index) => (
          <Ourskills
            key={index}
            image={skills.image}
            heading={skills.heading}
            description={skills.description}
          />
        ))}
      </div>
    </section>
  );
};

export default Mobileskills;
