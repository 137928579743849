import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import fullstackimg from "../Assets/fullstack2.png";
import backendimg from "../Assets/backendweb.png";
import frontendimg from "../Assets/frontend (2).png";

const Testingtypes = () => {
  return (
    <section className="pt-5 bg-white">
      <Container>
        <Row>
          <Col xs={12} md={4}>
            <div className="bg-dark webdevbody-col1  text-white">
              <h3 className="text-center">
                Why Choose QA Testers from cornerstone?
              </h3>
              <p className="px-3">
                Elevate your software quality with QA testers from cornerstone.
                Our seasoned professionals bring extensive expertise and
                industry-best practices to ensure thorough testing, meticulous
                bug detection, and optimal performance for your software,
                delivering superior results and enhancing customer satisfaction.
              </p>
            </div>
          </Col>

          <Col xs={12} md={8}>
            <div className="webdevbody-col2   mb-4 border border-2">
              <Row>
                <Col className="pe-0" xs={2} md={2}>
                  <div className="webdivbody-img ">
                    <img src={frontendimg} alt="" />
                  </div>
                </Col>

                <Col xs={10} md={10}>
                  <div>
                    <h4 className="pt-2 pe-3 ps-0">
                      Comprehensive End-to-End Validation
                    </h4>
                    <p className="pe-3">
                      Ensure seamless functionality and performance across all
                      aspects of your software with our end-to-end testing
                      services. Our expert testers meticulously assess every
                      component and feature, from front-end user interface to
                      back-end functionality, to identify and resolve any issues
                      and ensure a flawless user experience.
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="webdevbody-col2  mb-4 border border-2">
              <Row>
                <Col className="pe-0" xs={2} md={2}>
                  <div className="webdivbody-img ">
                    <img src={backendimg} alt="" />
                  </div>
                </Col>

                <Col xs={10} md={10}>
                  <div>
                    <h4 className="pt-2 ps-3">
                      Consistent Standardized Processes
                    </h4>
                    <p className="px-3">
                      Trust in our standardized testing procedures, meticulously
                      designed to ensure thorough and reliable testing across
                      all projects. With consistent processes in place, we
                      guarantee accurate and consistent results, enabling
                      efficient bug detection and resolution.
                    </p>
                  </div>
                </Col>
              </Row>
            </div>

            <div className="webdevbody-col2  mb-4 border border-2">
              <Row>
                <Col className="pe-0" xs={2} md={2}>
                  <div className="webdivbody-img ">
                    <img src={fullstackimg} alt="" />
                  </div>
                </Col>

                <Col xs={10} md={10}>
                  <div>
                    <h4 className="pt-2 ps-3">
                      Diverse Multi-Industry Expertise
                    </h4>
                    <p className="px-3">
                      Benefit from our extensive experience across multiple
                      industries, allowing us to bring diverse perspectives and
                      insights to your testing projects. Our team's
                      multi-industry expertise ensures that we understand your
                      unique requirements and challenges, delivering tailored
                      testing solutions that meet your specific needs and drive
                      success.
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Testingtypes;
