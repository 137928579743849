import React from "react";
import "../Ourskills/Ourskills.css";

const Ourskills = (skills) => {
  return (
    <section>
      <div className="row bg-white py-1 border border-2 ourskill-container">
        <div className="col col-3">
          <img src={skills.image} alt="" />
        </div>
        <div className="col col-9">
          <h4 className="pt-3">{skills.heading}</h4>
          <p className="px-3">{skills.description}</p>
        </div>
      </div>
    </section>
  );
};

export default Ourskills;
