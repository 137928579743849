import React from "react";
import Uxbanner from "../Components/Uxbanner/Uxbanner";
import Uioffers from "../Components/Uioffers/Uioffers";
import Homecontact from "../Components/Homecontact/Homecontact";
import Uiskills from "../Components/Uiskills/Uiskills";
import Uitypes from "../Components/Uitypes/Uitypes";

const Uidesign = () => {
  return (
    <div>
      <Uxbanner />
      <Uioffers />
      <Uiskills />
      <Homecontact />
      <Uitypes />
    </div>
  );
};

export default Uidesign;
