import React from "react";
import "../homeBanner/Homebanner.css";

import Card from "react-bootstrap/Card";
import image from "../Assets/homebanner2.jpg";
import Button from "react-bootstrap/Button";

const Homebanner = () => {
  return (
    <section className="home-banner">
      <Card className="text-white banner-container rounded-0 border-0 ">
        <Card.Img
          className="banner-image rounded-0 border-0"
          src={image}
          alt="Card image"
        />
        <Card.ImgOverlay>
          <Card.Title className="banner-title">
            Cornerstone Technologies111
          </Card.Title>
          <Card.Text className="banner-description">
          "Affordable rates, exceptional service fueling your business growth"
          </Card.Text>
          <div className="text-center mt-4">
            <a
              href="https://wa.me/+919618800449"
              rel="noreferrer"
              target="_blank"
            >
              <Button variant="outline-light">Know More</Button>
            </a>
          </div>
        </Card.ImgOverlay>
      </Card>
    </section>
  );
};

export default Homebanner;
