import React from "react";
import "../WebdevBody/Webdevbody.css";
import { Container, Row, Col } from "react-bootstrap";
import fullstackimg from "../Assets/fullstack2.png";
import backendimg from "../Assets/backendweb.png";
import frontendimg from "../Assets/frontend (2).png";
import WebDev1 from "../Assets/webdev.png";
const Webdevbody = () => {
  return (
    <section className="pt-5 bg-white">
      <Container>
        <Row>
          <Col xs={12} md={4}>
          <div className="bg-dark webdevbody-col1 text-white" style={{ background: 'linear-gradient(to bottom, #003FFF, #8ee5ee)', textAlign: 'justify', }}>            
            <Col className="pe-0" xs={12} md={12}>
                  <div className="webdivbody-img ">
                    <img src={WebDev1} alt="" />
                  </div>
                </Col>
            
              <h3 className="text-center">Web Development We Provide</h3>
              <p className="px-3">
                      Backend development deals with server-side logic,
                      databases, and application functionality that supports the
                      frontend. Common backend technologies include server-side
                      programming languages like Node.js (JavaScript), Python
                      (Django, Flask), Ruby (Ruby on Rails), PHP (Laravel), and
                      Java (Spring).
              </p>
            </div>
          </Col>

          <Col xs={12} md={8}>
            <div className="webdevbody-col2   mb-4 border border-2">
              <Row>
                <Col className="pe-0" xs={2} md={2}>
                  <div className="webdivbody-img ">
                    <img src={frontendimg} alt="" />
                  </div>
                </Col>

                <Col xs={10} md={10}>
                  <div>
                    <h4 className="pt-2 pe-3 ps-0">Frontend Development:</h4>
                    <p className="pe-3">
                      Frontend development focuses on the client-side of web
                      applications, dealing with the user interface and user
                      experience. Technologies commonly used in frontend
                      development include HTML, CSS, and JavaScript. Frontend
                      frameworks/libraries like React.js, Angular, and Vue.js
                      are often used to build dynamic and interactive user
                      interfaces.
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="webdevbody-col2  mb-4 border border-2">
              <Row>
                <Col className="pe-0" xs={2} md={2}>
                  <div className="webdivbody-img ">
                    <img src={backendimg} alt="" />
                  </div>
                </Col>

                <Col xs={10} md={10}>
                  <div>
                    <h4 className="pt-2 ps-3">Backend Development:</h4>
                    <p className="px-3">
                      Backend development deals with server-side logic,
                      databases, and application functionality that supports the
                      frontend. Common backend technologies include server-side
                      programming languages like Node.js (JavaScript), Python
                      (Django, Flask), Ruby (Ruby on Rails), PHP (Laravel), and
                      Java (Spring). Backend frameworks and platforms provide
                      tools for building and managing server-side components,
                      handling requests, and managing data.
                    </p>
                  </div>
                </Col>
              </Row>
            </div>

            <div className="webdevbody-col2  mb-4 border border-2">
              <Row>
                <Col className="pe-0" xs={2} md={2}>
                  <div className="webdivbody-img ">
                    <img src={fullstackimg} alt="" />
                  </div>
                </Col>

                <Col xs={10} md={10}>
                  <div>
                    <h4 className="pt-2 ps-3">Full-stack Development:</h4>
                    <p className="px-3">
                      Full-stack developers are proficient in both frontend and
                      backend development, capable of working on all aspects of
                      web development. They can develop both the client-side and
                      server-side components of web applications. Full-stack
                      development requires knowledge of a wide range of
                      technologies and frameworks.
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Webdevbody;
