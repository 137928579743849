import React from "react";
import "../Uioffers/Uioffers.css";
import Weoffer from "../Weoffer/Weoffer";
import uioffers1 from "../Assets/uioffers1.png";
import uioffers2 from "../Assets/uioffers2.png";
import uioffers3 from "../Assets/uioffers3.png";
import uioffers4 from "../Assets/uioffers4.png";
import uioffers5 from "../Assets/uioffers5.png";
import uioffers6 from "../Assets/uioffers6.png";

const uidata = [
  {
    id: 1,
    image: uioffers1,
    heading: "Strategic Wireframe Prototyping",
    description:
      "Lay the foundation for your digital product's success with our strategic wireframing services. Our expert designers create comprehensive wireframes that outline the structure and functionality of your application, facilitating efficient development and ensuring alignment with your business goals and user needs. ",
  },
  {
    id: 2,
    image: uioffers2,
    heading: "Innovative Product Design Solutions",
    description:
      "Elevate your product's visual appeal and functionality with our innovative product design services. Our talented designers combine creativity and usability to deliver captivating designs that enhance user engagement and drive business growth.  ",
  },
  {
    id: 3,
    image: uioffers3,
    heading: "Wearable App Experience Crafting",
    description:
      "Harness the potential of wearable technology with our specialized wearables app design services. Our experienced designers create intuitive and visually appealing interfaces optimized for wearable devices, ensuring a seamless user experience and maximizing the value of your wearable applications.  ",
  },
  {
    id: 4,
    image: uioffers4,
    heading: "Tailored Mobile App Interface Design",
    description:
      "Enhance user satisfaction and retention with our tailored mobile app design services. Our skilled designers create intuitive and visually appealing interfaces that captivate users and deliver a seamless mobile experience across all platforms and devices.  ",
  },
  {
    id: 5,
    image: uioffers5,
    heading: "Custom Web Design and Development",
    description:
      "Make a lasting impression online with our custom web design and development solutions. Our expert designers and developers collaborate to create stunning websites that reflect your brand identity, engage visitors, and drive conversions, ensuring a memorable online presence for your business. ",
  },
  {
    id: 6,
    image: uioffers6,
    heading: "Corporate Branding and Graphic Design",
    description:
      "Establish a strong brand identity and stand out in the market with our corporate branding and graphics design services. From logo design to marketing materials, our talented designers create cohesive visual assets that convey your brand's values and personality, leaving a lasting impression on your audience.  ",
  },
];

const Uioffers = () => {
  return (
    <section className="bg-light pb-5">
      <div className="pt-5 text-center">
        <h3>Our Comprehensive UI/UX Design Solutions</h3>
        <p className="px-5">
          Experience our full range of UI/UX design services tailored to enhance
          user engagement and satisfaction. From intuitive interface design to
          seamless user experience optimization, our expert team ensures that
          your digital products captivate users and drive success for your
          business.
        </p>
      </div>
      <div className=" uioffer-container">
        {uidata.map((offers, index) => (
          <Weoffer
            key={index}
            image={offers.image}
            heading={offers.heading}
            description={offers.description}
          />
        ))}
      </div>
    </section>
  );
};

export default Uioffers;
