import React from "react";
import "../Testingbanner/Testingbanner.css";
import testingbannerimg from "../Assets/testingbanner.jpg";
import Button from "react-bootstrap/Button";

const Testingbanner = () => {
  return (
    <section>
      <div className="testingbanner-container">
        <img src={testingbannerimg} alt="" />
        <div className="testingbanner-container-content text-center text-white">
          <h1>Software Testing Services </h1>
          <p className="">
            At cornerstone, our software quality assurance services aim to
            eliminate vulnerabilities and elevate the performance,
            compatibility, usability, and accessibility of your software. Our
            team comprises industry-certified security professionals who
            meticulously evaluate every aspect, from code blocks and APIs to
            databases and front-end components. Trust cornerstone for
            high-quality QA testing services at competitive rates, ensuring your
            software meets the highest standards without breaking your budget.
          </p>
          <div className=" testingbanner-button text-center p-3">
            <a
              href="https://wa.me/+919618800449"
              rel="noreferrer"
              target="_blank"
            >
              <Button variant="light" className="rounded-5   px-4 py-2">
                Let's Talk
              </Button>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testingbanner;
