import React from "react";
import "../WebAppTypes/Webapp.css";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import staticimg from "../Assets/staticapp.png";
import dynamicimg from "../Assets/dinamic.png";
import realtimeimg from "../Assets/realtime.png";
import ecommerceimg from "../Assets/ecomdev.png";
import singleimg from "../Assets/singlepage.png";
import pwaimg from "../Assets/Progressive.png";
import socialimg from "../Assets/social.png";
import cmsimg from "../Assets/Content.png";
import portelimg from "../Assets/portel.png";

const Webapp = () => {
  return (
    <section className="bg-light pt-5">
      <div className="text-center hometech-container pt-3 ">
        <h3 className="py-3">Types of Web Applications</h3>
        <p className="text-center hometech-container-text ">
          Web applications are software applications that run on web browsers.
          They provide interactive experiences to users and often involve
          dynamic content and server-side processing. Here are some common types
          of web applications:
        </p>

        <Container>
          <Row className="justify-content-center gx-4">
            <Col sm={12} md={6} lg={4} className="mb-3 ">
              <div className="border border-2  bg-white pb-5 webapp-col">
                <img src={staticimg} alt="" />
                <h4>Static Web Applications</h4>
                <p className="text-content ">
                  These are basic web applications that serve static content to
                  users. Content remains fixed and does not change based on user
                  input or actions. Examples include informational websites,
                  landing pages, and portfolios.
                </p>
              </div>
            </Col>
            <Col sm={12} md={6} lg={4} className=" mb-3">
              <div className="border border-2 bg-white pb-5 webapp-col">
                <img src={dynamicimg} alt="" />
                <h4>Dynamic Web Applications</h4>
                <p className="text-content ">
                  Dynamic web applications generate content dynamically in
                  response to user interactions or data from a database. Content
                  can vary based on user input, session data, or external
                  factors. Examples include social media platforms, e-commerce
                  websites, and online banking systems.
                </p>
              </div>
            </Col>
            <Col sm={12} md={6} lg={4} className="mb-3 ">
              <div className="border border-2 bg-white pb-5 webapp-col">
                <img src={singleimg} alt="" />
                <h4>Single Page Applications (SPAs)</h4>
                <p className="text-content ">
                  SPAs are web applications that load a single HTML page and
                  dynamically update the content as the user interacts with the
                  application. They provide a more fluid user experience by
                  avoiding page reloads for every interaction. Examples include
                  Gmail, Google Maps, and Trello.
                </p>
              </div>
            </Col>

            <Col sm={12} md={6} lg={4} className="mb-3">
              <div className="border border-2 bg-white pb-5 webapp-col">
                <img src={pwaimg} alt="" />
                <h4>Progressive Web Applications (PWAs)</h4>
                <p className="text-content ">
                  PWAs are web applications that leverage modern web
                  technologies to provide a native app-like experience on web
                  browsers. They can work offline, send push notifications, and
                  access device features like cameras and geolocation. Examples
                  include Twitter Lite, Flipkart, and Starbucks.
                </p>
              </div>
            </Col>
            <Col sm={12} md={6} lg={4} className="mb-3 ">
              <div className="border border-2 bg-white pb-5 webapp-col">
                <img src={ecommerceimg} alt="" />
                <h4>E-commerce Web Applications</h4>
                <p className="text-content ">
                  E-commerce web applications facilitate online buying and
                  selling of goods and services. They include features like
                  product catalogs, shopping carts, payment gateways, and order
                  management systems. Examples include Amazon, eBay, and
                  Shopify.
                </p>
              </div>
            </Col>
            <Col sm={12} md={6} lg={4} className="mb-3 ">
              <div className="border border-2 bg-white pb-5 webapp-col">
                <img src={socialimg} alt="" />
                <h4>Social Networking Applications</h4>
                <p className="text-content ">
                  Social networking applications allow users to connect, share
                  content, and communicate with others. They include features
                  like user profiles, news feeds, messaging, and content
                  sharing. Examples include Facebook, LinkedIn, and Instagram.
                </p>
              </div>
            </Col>

            <Col sm={12} md={6} lg={4} className="mb-3">
              <div className="border border-2 bg-white pb-5 webapp-col">
                <img src={cmsimg} alt="" />
                <h4>Content Management Systems (CMS)</h4>
                <p className="text-content ">
                  CMS web applications enable users to create, manage, and
                  publish digital content on the web. They provide tools for
                  content creation, editing, organization, and publishing.
                  Examples include WordPress, Joomla, and Drupal.
                </p>
              </div>
            </Col>
            <Col sm={12} md={6} lg={4} className="mb-3 ">
              <div className="border border-2 bg-white pb-5 webapp-col">
                <img src={realtimeimg} alt="" />
                <h4>Real-Time Web Applications</h4>
                <p className="text-content ">
                  Real-time web applications deliver data or updates to users in
                  real-time without requiring them to refresh the page. They
                  often use technologies like WebSockets or server-sent events
                  to establish persistent connections between clients and
                  servers. Examples include chat applications, live sports
                  updates, and collaborative editing tools.
                </p>
              </div>
            </Col>
            <Col sm={12} md={6} lg={4} className="mb-3 ">
              <div className="border border-2 bg-white pb-5 webapp-col">
                <img src={portelimg} alt="" />
                <h4>Portal Web Applications</h4>
                <p className="text-content ">
                  Portal web applications serve as centralized platforms for
                  accessing information, resources, and services from various
                  sources. They aggregate content and functionality from
                  multiple sources into a single interface, providing users with
                  personalized and convenient access. Examples include corporate
                  intranets, educational portals, and government service
                  portals.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
};

export default Webapp;
