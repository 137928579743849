import React from "react";

import Homeservices from "../Components/Homeservices/Homeservices";
import Hiring from "../Components/Hiring/Hiring";

import Banner from "../Components/Banner/Banner";
import bannerimage from "../Components/Assets/banner7.jpg";
import Homeabout from "../Components/Homeabout/Homeabout";
import Pricing from "../Components/Pricing/Pricing";
import Aboutskills from "../Components/Aboutskills/Aboutskills";
import Contactform from "../Components/Contactform/Contactform";
import Aboutus from "../Components/Aboutus/Aboutus";

const About = () => {
  return (
    <div>
      <Banner
        img={bannerimage}
        title="About Cornerstone"
        description="
Established in 2015, Cornerstone has earned renown as a trusted and dependable software development firm. Our adept, data-informed, and specialized team are the cornerstone of our distinction from other IT outsourcing entities and enterprises."
      />
      <Aboutus />
      <Homeabout />
      <Hiring />
      <Homeservices />

      <Aboutskills />
      <Pricing />
      <Contactform />
    </div>
  );
};

export default About;
