import React from "react";
import "../Careerbody/Careerbody.css";
import { Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";

const Careerbody = () => {
  const emailAddress = "   myvillage.523247@gmail.com";

  const handleEmailButtonClick = () => {
    window.location.href = `mailto:${emailAddress}`;
  };

  return (
    <section className="bg-light pt-5">
      <div className="text-center">
        <h3>Explore Our Current Job Opportunities</h3>
        <p>
          Embark on a fresh chapter in your professional journey, paving the way
          to success through expert guidance, global client networking, and a
          collaborative culture.
        </p>
      </div>
      <Container>
        <Row className=" pt-3 pb-3">
          <Col sm={12} md={6} className=" pb-3 ">
            <div className="border border-1 bg-white py-5 px-4">
              <h4>Front-End Developer </h4>
              <p>
                Front End Executive with 0-5 years experience in ReactJS,
                Typescript, Javascript and React Native
              </p>
              <p>
                <b>Years of Experience:</b>0 – 5 years
              </p>
              <p>
                <b>No. of vacancy:</b>3
              </p>
              <p>
                <b>Location:</b>Andhra Pradesh, India
              </p>
              <Button
                variant="warning"
                className="rounded-5 fs-6 px-4"
                onClick={handleEmailButtonClick}
              >
                Apply Now
              </Button>
            </div>
          </Col>
          <Col sm={12} md={6} className=" pb-3 ">
            <div className="border border-1 bg-white py-5 px-4">
              <h4> ASP.NET Developer </h4>
              <p>
                ASP.NET Developers with 3-5 years experience in ASP.NET Core,
                ASP.NET MVC, ASP.NET Webforms, Web API and SQL Server
              </p>
              <p>
                <b>Years of Experience:</b>0 – 5 years
              </p>
              <p>
                <b>No. of vacancy:</b>1
              </p>
              <p>
                <b>Location:</b>Andhra Pradesh, India
              </p>
              <Button
                variant="warning"
                className="rounded-5 fs-6 px-4"
                onClick={handleEmailButtonClick}
              >
                Apply Now
              </Button>
            </div>
          </Col>
          <Col sm={12} md={6} className=" pb-3 ">
            <div className="border border-1 bg-white py-5 px-4">
              <h4> QA Analyst</h4>
              <p>
                QA Analyst with 0-5 years experience in Unit Testing,
                Integration Testing, Software Assurance and QA
              </p>
              <p>
                <b>Years of Experience:</b>0 – 5 years
              </p>
              <p>
                <b>No. of vacancy:</b>2
              </p>
              <p>
                <b>Location:</b>Andhra Pradesh, India
              </p>
              <Button
                variant="warning"
                className="rounded-5 fs-6 px-4"
                onClick={handleEmailButtonClick}
              >
                Apply Now
              </Button>
            </div>
          </Col>
          <Col sm={12} md={6} className=" pb-3 ">
            <div className="border border-1 bg-white py-5 px-4">
              <h4>PHP Developer</h4>
              <p>
                PHP Developers having 0-5 years experience in Core PHP, WHMCS,
                Woocommerce and WordPress
              </p>
              <p>
                <b>Years of Experience:</b>0 – 5 years
              </p>
              <p>
                <b>No. of vacancy:</b>4
              </p>
              <p>
                <b>Location:</b>Andhra Pradesh, India
              </p>
              <Button
                variant="warning"
                className="rounded-5 fs-6 px-4"
                onClick={handleEmailButtonClick}
              >
                Apply Now
              </Button>
            </div>
          </Col>
          <Col sm={12} md={6} className=" pb-3 ">
            <div className="border border-1 bg-white py-5 px-4">
              <h4>Web Designer</h4>
              <p>
                Web Design having 0-5 years expereience in UI/UX, Front end
                technologies like React/Angular, HTML, CSS, Bootstrap etc.
              </p>
              <p>
                <b>Years of Experience:</b>0 – 5 years
              </p>
              <p>
                <b>No. of vacancy:</b>2
              </p>
              <p>
                <b>Location:</b>Andhra Pradesh, India
              </p>
              <Button
                variant="warning"
                className="rounded-5 fs-6 px-4"
                onClick={handleEmailButtonClick}
              >
                Apply Now
              </Button>
            </div>
          </Col>
          <Col sm={12} md={6} className=" pb-3 ">
            <div className="border border-1 bg-white py-5 px-4">
              <h4>WordPress Developer</h4>
              <p>
                WordPress developers having 1-3 years expereince in WordPress,
                PHP, Plugins, Themes and woocommerce
              </p>
              <p>
                <b>Years of Experience:</b>1 – 3 years
              </p>
              <p>
                <b>No. of vacancy:</b>1
              </p>
              <p>
                <b>Location:</b>Andhra Pradesh, India
              </p>
              <Button
                variant="warning"
                className="rounded-5 fs-6 px-4"
                onClick={handleEmailButtonClick}
              >
                Apply Now
              </Button>
            </div>
          </Col>
          <Col sm={12} md={6} className=" pb-3 ">
            <div className="border border-1 bg-white py-5 px-4">
              <h4>System Administrator</h4>
              <p>
                System Administration with experience of 0-5 years in Windows
                and Linux administration and knowledge of SSL installation
              </p>
              <p>
                <b>Years of Experience:</b>1 – 3 years
              </p>
              <p>
                <b>No. of vacancy:</b>1
              </p>
              <p>
                <b>Location:</b>Andhra Pradesh, India
              </p>
              <Button
                variant="warning"
                className="rounded-5 fs-6 px-4"
                onClick={handleEmailButtonClick}
              >
                Apply Now
              </Button>
            </div>
          </Col>
          <Col sm={12} md={6} className=" pb-3 ">
            <div className="border border-1 bg-white py-5 px-4">
              <h4>Customer Support Representative</h4>
              <p>
                Customer Support Executive with 0-5 years experience in Customer
                Support and having excellent command over English
              </p>
              <p>
                <b>Years of Experience:</b>0 – 5 years
              </p>
              <p>
                <b>No. of vacancy:</b>1
              </p>
              <p>
                <b>Location:</b>Andhra Pradesh, India
              </p>
              <Button
                variant="warning"
                className="rounded-5 fs-6 px-4"
                onClick={handleEmailButtonClick}
              >
                Apply Now
              </Button>
            </div>
          </Col>
          <Col sm={12} md={6} className=" pb-3 ">
            <div className="border border-1 bg-white py-5 px-4">
              <h4>Technical Content Writer</h4>
              <p>
                Technical Content Writer with 0-5 years experience in Technical
                Content and having excellent command over English
              </p>
              <p>
                <b>Years of Experience:</b>0 – 5 years
              </p>
              <p>
                <b>No. of vacancy:</b>1
              </p>
              <p>
                <b>Location:</b>Andhra Pradesh, India
              </p>
              <Button
                variant="warning"
                className="rounded-5 fs-6 px-4"
                onClick={handleEmailButtonClick}
              >
                Apply Now
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Careerbody;
