import React from "react";
import "../Ecombanner/Ecombanner.css";
import ecombanner from "../Assets/ecombanner.png";
import Button from "react-bootstrap/Button";
import { Container, Row, Col } from "react-bootstrap";

const Ecombanner = () => {
  return (
    <section className="conatainer-fluid ecombanner-section">
      <Container className="pt-5 ecombanner-container1">
        <Row>
          <Col xs={12} md={6} lg={6} className=" order-2 order-md-1 pt-5">
            <div className=" ecombanner-section-content text-white text-center pt-5">
              <h2>Ecommerce App Development Services We Offer</h2>
              <p className="">
                Cornerstone has established itself as a trusted name in
                eCommerce web design and development since 2015. We specialize
                in crafting captivating webstores tailored to your specific
                audience, ensuring seamless functionality, robust security, and
                seamless integration with essential third-party services and
                systems. Let us bring your vision to life.
              </p>
              <div className=" text-center p-3">
                <a
                  href="https://wa.me/+919618800449"
                  rel="noreferrer"
                  target="_blank"
                >
                  <Button variant="light" className="rounded-5 px-4 py-2">
                    Let's Talk
                  </Button>
                </a>
              </div>
            </div>
          </Col>
          <Col
            xs={12}
            md={6}
            lg={6}
            className="order-1 order-md-2  text-center"
          >
            <div className=" ecombanner-section-image ">
              <img src={ecombanner} alt="" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Ecombanner;
