import React from "react";
import "../Industrybody/Industrybody.css";
import { Container, Row, Col } from "react-bootstrap";
import constuctionimg from "../Assets/constuction.png";
import healthimg from "../Assets/healthcare.png";
import manufacturimg from "../Assets/manufacturing.png";
import agriimg from "../Assets/agriculture.png";
import entertainimg from "../Assets/entertainment.png";
import fashionimg from "../Assets/fashion.png";
import autoimg from "../Assets/automobiles.png";
import bankimg from "../Assets/banking.png";
import educationimg from "../Assets/education.png";

const Industrybody = () => {
  return (
    <section className=" bg-light industrybody-section">
      {/* constuction part */}
      <div className="bigscreen-container pb-5">
        <div className=" py-5">
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <div className="industry-container-col1">
                  <div className="text-center pt-5 img-div ">
                    <h1>Constuction</h1>
                    <img className="industry-img" src={constuctionimg} alt="" />
                  </div>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="industry-container-col2 p-5">
                  <h5 className="pt-5">
                    We can provide tailored solutions for construction firms,
                    including:
                  </h5>
                  <ul>
                    <li>
                      Custom software development for project management,
                      scheduling, and budgeting.
                    </li>
                    <li>
                      Mobile applications for on-site access to project data and
                      communication.
                    </li>
                    <li>
                      Building Information Modeling (BIM) software for
                      visualization and collaboration.
                    </li>

                    <li>
                      IoT solutions for construction site monitoring and
                      equipment management.
                    </li>
                    <li>
                      Supply chain management systems to streamline procurement
                      and inventory management.
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {/* Education part */}
        <div className=" py-5">
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <div className="industry-container-col2 p-5">
                  <h5 className="pt-5">
                    For the Education Sector, our tailored solutions encompass:
                  </h5>
                  <ul>
                    <li>
                      Custom Learning Management Systems (LMS) for seamless
                      course management and student engagement.
                    </li>
                    <li>
                      Mobile applications for remote learning, interactive
                      content delivery, and student-teacher communication.
                    </li>
                    <li>
                      Student Information Systems (SIS) for efficient student
                      record management and academic performance tracking.
                    </li>

                    <li>
                      Virtual Classroom solutions for live lectures,
                      discussions, and collaborative learning experiences.
                    </li>
                    <li>
                      Education Analytics platforms for data-driven insights
                      into student progress and institutional performance.
                    </li>
                  </ul>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="industry-container-col1">
                  <div className="text-center pt-5 img-div ">
                    <h1>Education Sector</h1>
                    <img className="industry-img" src={educationimg} alt="" />
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        {/* health care part */}
        <div className="py-5">
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <div className="industry-container-col1">
                  <div className="text-center pt-5 img-div ">
                    <h1>Healthcare</h1>
                    <img className="industry-img" src={healthimg} alt="" />
                  </div>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="industry-container-col2 p-5">
                  <h5 className="pt-5">
                    For Healthcare, our tailored solutions include:
                  </h5>
                  <ul>
                    <li>
                      Electronic Health Record (EHR) systems for comprehensive
                      patient data management and medical history tracking.
                    </li>
                    <li>
                      Telemedicine platforms for remote consultations,
                      diagnosis, and treatment planning.
                    </li>
                    <li>
                      Healthcare Mobile Apps for appointment scheduling,
                      medication reminders, and telehealth services.
                    </li>

                    <li>
                      Medical Imaging solutions for diagnostic imaging,
                      analysis, and reporting.
                    </li>
                    <li>
                      Hospital Management Systems (HMS) for operational
                      efficiency, resource optimization, and patient care
                      coordination.
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        {/* manufacturing part */}

        <div className="py-5">
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <div className="industry-container-col2 p-5">
                  <h5 className="pt-5">
                    We can provide tailored solutions for Manufacturing firms,
                    including:
                  </h5>
                  <ul>
                    <li>
                      Custom software development for production planning,
                      inventory management, and quality control.
                    </li>
                    <li>
                      Mobile applications for real-time access to factory floor
                      data, production monitoring, and inventory tracking.
                    </li>
                    <li>
                      Computer-Aided Design (CAD) and Computer-Aided
                      Manufacturing (CAM) software for product design and
                      manufacturing automation.
                    </li>

                    <li>
                      IoT solutions for predictive maintenance, equipment
                      monitoring, and asset tracking to optimize production
                      efficiency.
                    </li>
                    <li>
                      Enterprise Resource Planning (ERP) systems for integrated
                      management of manufacturing processes, supply chain, and
                      financials.
                    </li>
                  </ul>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="industry-container-col1">
                  <div className="text-center pt-5 img-div ">
                    <h1>Manufacturing</h1>
                    <img className="industry-img" src={manufacturimg} alt="" />
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        {/* Agriculture part */}
        <div className="py-5">
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <div className="industry-container-col1">
                  <div className="text-center pt-5 img-div ">
                    <h1>Agriculture</h1>
                    <img className="industry-img" src={agriimg} alt="" />
                  </div>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="industry-container-col2 p-5">
                  <h5 className="pt-5">
                    We can provide tailored solutions for Agriculture firms,
                    including:
                  </h5>
                  <ul>
                    <li>
                      Custom software development for farm management, crop
                      planning, and yield optimization.
                    </li>
                    <li>
                      Mobile applications for field data collection, crop
                      monitoring, and pest management.
                    </li>
                    <li>
                      Geographic Information System (GIS) software for land
                      mapping, soil analysis, and precision agriculture.
                    </li>

                    <li>
                      IoT solutions for environmental monitoring, irrigation
                      control, and livestock tracking.
                    </li>
                    <li>
                      Supply chain management systems for farm-to-table
                      traceability, inventory management, and distribution
                      optimization.
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        {/* entertainment part */}
        <div className="py-5">
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <div className="industry-container-col2 p-5">
                  <h5 className="pt-5">
                    We specialize in delivering bespoke solutions tailored to
                    the entertainment industry, such as:
                  </h5>
                  <ul>
                    <li>
                      Customized software development for event management,
                      ticketing, and scheduling.
                    </li>
                    <li>
                      Engaging mobile applications for fans to access exclusive
                      content, interact with artists, and purchase merchandise.
                    </li>
                    <li>
                      Virtual event platforms and live streaming solutions for
                      remote audience engagement.
                    </li>

                    <li>
                      Content management systems for organizing and distributing
                      multimedia content across various channels.
                    </li>
                    <li>
                      Analytics and insights tools to understand audience
                      behavior and optimize marketing strategies for maximum
                      impact.
                    </li>
                  </ul>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="industry-container-col1">
                  <div className="text-center pt-5 img-div ">
                    <h1>Entertainment</h1>
                    <img className="industry-img" src={entertainimg} alt="" />
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {/* fashion part */}

        <div className="py-5">
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <div className="industry-container-col1">
                  <div className="text-center pt-5 img-div ">
                    <h1>Fashion Zone</h1>
                    <img className="industry-img" src={fashionimg} alt="" />
                  </div>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="industry-container-col2 p-5">
                  <h5 className="pt-5">
                    In the dynamic world of fashion, we offer specialized
                    services designed to elevate your brand, including:
                  </h5>
                  <ul>
                    <li>
                      Custom e-commerce solutions for online stores, offering
                      seamless shopping experiences and secure transactions.
                    </li>
                    <li>
                      Mobile apps for fashion enthusiasts to browse collections,
                      receive style recommendations, and make purchases on the
                      go.
                    </li>
                    <li>
                      Visual merchandising software for creating stunning
                      product displays and optimizing store layouts.
                    </li>

                    <li>
                      Inventory management systems to track stock levels,
                      monitor trends, and streamline order fulfillment
                      processes.
                    </li>
                    <li>
                      Personalization tools for delivering tailored shopping
                      experiences, including size recommendations and style
                      suggestions based on individual preferences.
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {/* automobiles part */}
        <div className="py-5">
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <div className="industry-container-col2 p-5">
                  <h5 className="pt-5">
                    We specialize in delivering bespoke solutions tailored for
                    automotive enterprises, including:
                  </h5>
                  <ul>
                    <li>
                      Custom software development for inventory management,
                      sales tracking, and customer relationship management
                      (CRM).
                    </li>
                    <li>
                      Mobile applications for sales representatives and service
                      technicians to access vehicle information and manage
                      appointments on the go.
                    </li>
                    <li>
                      Augmented Reality (AR) applications for virtual showroom
                      experiences and interactive vehicle demonstrations.
                    </li>

                    <li>
                      IoT solutions for real-time monitoring of vehicle
                      performance, predictive maintenance, and fleet management.
                    </li>
                    <li>
                      Supply chain management systems to optimize procurement,
                      inventory tracking, and logistics for automotive parts and
                      accessories.
                    </li>
                  </ul>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="industry-container-col1">
                  <div className="text-center pt-5 img-div ">
                    <h1>Automobiles</h1>
                    <img className="industry-img" src={autoimg} alt="" />
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {/* banking part */}

        <div className="py-5">
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <div className="industry-container-col1">
                  <div className="text-center pt-5 img-div ">
                    <h1>Banking</h1>
                    <img className="industry-img" src={bankimg} alt="" />
                  </div>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="industry-container-col2 p-5">
                  <h5 className="pt-5">
                    We offer comprehensive software solutions designed
                    specifically for financial institutions, covering:
                  </h5>
                  <ul>
                    <li>
                      Custom software development for banking operations,
                      including account management, transaction processing, and
                      risk assessment.
                    </li>
                    <li>
                      Mobile banking applications for customers to access
                      accounts, make transactions, and receive personalized
                      financial insights.
                    </li>
                    <li>
                      AI-powered chatbots for customer support, providing
                      assistance with account inquiries, loan applications, and
                      financial advice.
                    </li>

                    <li>
                      Blockchain solutions for secure and transparent
                      transactions, smart contracts, and identity verification.
                    </li>
                    <li>
                      Compliance and regulatory software to ensure adherence to
                      industry standards and mitigate risks associated with
                      fraud and money laundering.
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>

      {/* for responsive model */}

      <div className=" mobile-container pt-2">
        <div>
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <div className="industry-container-col1">
                  <div className="text-center pt-5 img-div ">
                    <h1>Constuction</h1>
                    <img className="industry-img" src={constuctionimg} alt="" />
                  </div>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="industry-container-col2 p-5">
                  <h5 className="pt-5">
                    We can provide tailored solutions for construction firms,
                    including:
                  </h5>
                  <ul>
                    <li>
                      Custom software development for project management,
                      scheduling, and budgeting.
                    </li>
                    <li>
                      Mobile applications for on-site access to project data and
                      communication.
                    </li>
                    <li>
                      Building Information Modeling (BIM) software for
                      visualization and collaboration.
                    </li>

                    <li>
                      IoT solutions for construction site monitoring and
                      equipment management.
                    </li>
                    <li>
                      Supply chain management systems to streamline procurement
                      and inventory management.
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {/* Education part */}
        <div className="">
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <div className="industry-container-col1">
                  <div className="text-center pt-5 img-div ">
                    <h1>Education Sector</h1>
                    <img className="industry-img" src={educationimg} alt="" />
                  </div>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="industry-container-col2 p-5">
                  <h5 className="pt-5">
                    For the Education Sector, our tailored solutions encompass:
                  </h5>
                  <ul>
                    <li>
                      Custom Learning Management Systems (LMS) for seamless
                      course management and student engagement.
                    </li>
                    <li>
                      Mobile applications for remote learning, interactive
                      content delivery, and student-teacher communication.
                    </li>
                    <li>
                      Student Information Systems (SIS) for efficient student
                      record management and academic performance tracking.
                    </li>

                    <li>
                      Virtual Classroom solutions for live lectures,
                      discussions, and collaborative learning experiences.
                    </li>
                    <li>
                      Education Analytics platforms for data-driven insights
                      into student progress and institutional performance.
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        {/* health care part */}
        <div className="">
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <div className="industry-container-col1">
                  <div className="text-center pt-5 img-div ">
                    <h1>Healthcare</h1>
                    <img className="industry-img" src={healthimg} alt="" />
                  </div>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="industry-container-col2 p-5">
                  <h5 className="pt-5">
                    For Healthcare, our tailored solutions include:
                  </h5>
                  <ul>
                    <li>
                      Electronic Health Record (EHR) systems for comprehensive
                      patient data management and medical history tracking.
                    </li>
                    <li>
                      Telemedicine platforms for remote consultations,
                      diagnosis, and treatment planning.
                    </li>
                    <li>
                      Healthcare Mobile Apps for appointment scheduling,
                      medication reminders, and telehealth services.
                    </li>

                    <li>
                      Medical Imaging solutions for diagnostic imaging,
                      analysis, and reporting.
                    </li>
                    <li>
                      Hospital Management Systems (HMS) for operational
                      efficiency, resource optimization, and patient care
                      coordination.
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        {/* manufacturing part */}

        <div className="">
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <div className="industry-container-col1">
                  <div className="text-center pt-5 img-div ">
                    <h1>Manufacturimg</h1>
                    <img className="industry-img" src={manufacturimg} alt="" />
                  </div>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="industry-container-col2 p-5">
                  <h5 className="pt-5">
                    We can provide tailored solutions for Manufacturing firms,
                    including:
                  </h5>
                  <ul>
                    <li>
                      Custom software development for production planning,
                      inventory management, and quality control.
                    </li>
                    <li>
                      Mobile applications for real-time access to factory floor
                      data, production monitoring, and inventory tracking.
                    </li>
                    <li>
                      Computer-Aided Design (CAD) and Computer-Aided
                      Manufacturing (CAM) software for product design and
                      manufacturing automation.
                    </li>

                    <li>
                      IoT solutions for predictive maintenance, equipment
                      monitoring, and asset tracking to optimize production
                      efficiency.
                    </li>
                    <li>
                      Enterprise Resource Planning (ERP) systems for integrated
                      management of manufacturing processes, supply chain, and
                      financials.
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        {/* Agriculture part */}
        <div className="">
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <div className="industry-container-col1">
                  <div className="text-center pt-5 img-div ">
                    <h1>Agriculture</h1>
                    <img className="industry-img" src={agriimg} alt="" />
                  </div>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="industry-container-col2 p-5">
                  <h5 className="pt-5">
                    We can provide tailored solutions for Agriculture firms,
                    including:
                  </h5>
                  <ul>
                    <li>
                      Custom software development for farm management, crop
                      planning, and yield optimization.
                    </li>
                    <li>
                      Mobile applications for field data collection, crop
                      monitoring, and pest management.
                    </li>
                    <li>
                      Geographic Information System (GIS) software for land
                      mapping, soil analysis, and precision agriculture.
                    </li>

                    <li>
                      IoT solutions for environmental monitoring, irrigation
                      control, and livestock tracking.
                    </li>
                    <li>
                      Supply chain management systems for farm-to-table
                      traceability, inventory management, and distribution
                      optimization.
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        {/* entertainment part */}
        <div className="">
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <div className="industry-container-col1">
                  <div className="text-center pt-5 img-div ">
                    <h1>Entertainment</h1>
                    <img className="industry-img" src={entertainimg} alt="" />
                  </div>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="industry-container-col2 p-5">
                  <h5 className="pt-5">
                    We specialize in delivering bespoke solutions tailored to
                    the entertainment industry, such as:
                  </h5>
                  <ul>
                    <li>
                      Customized software development for event management,
                      ticketing, and scheduling.
                    </li>
                    <li>
                      Engaging mobile applications for fans to access exclusive
                      content, interact with artists, and purchase merchandise.
                    </li>
                    <li>
                      Virtual event platforms and live streaming solutions for
                      remote audience engagement.
                    </li>

                    <li>
                      Content management systems for organizing and distributing
                      multimedia content across various channels.
                    </li>
                    <li>
                      Analytics and insights tools to understand audience
                      behavior and optimize marketing strategies for maximum
                      impact.
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {/* fashion part */}

        <div className="">
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <div className="industry-container-col1">
                  <div className="text-center pt-5 img-div ">
                    <h1>Fashion Zone</h1>
                    <img className="industry-img" src={fashionimg} alt="" />
                  </div>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="industry-container-col2 p-5">
                  <h5 className="pt-5">
                    In the dynamic world of fashion, we offer specialized
                    services designed to elevate your brand, including:
                  </h5>
                  <ul>
                    <li>
                      Custom e-commerce solutions for online stores, offering
                      seamless shopping experiences and secure transactions.
                    </li>
                    <li>
                      Mobile apps for fashion enthusiasts to browse collections,
                      receive style recommendations, and make purchases on the
                      go.
                    </li>
                    <li>
                      Visual merchandising software for creating stunning
                      product displays and optimizing store layouts.
                    </li>

                    <li>
                      Inventory management systems to track stock levels,
                      monitor trends, and streamline order fulfillment
                      processes.
                    </li>
                    <li>
                      Personalization tools for delivering tailored shopping
                      experiences, including size recommendations and style
                      suggestions based on individual preferences.
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {/* automobiles part */}
        <div className="">
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <div className="industry-container-col1">
                  <div className="text-center pt-5 img-div ">
                    <h1>Automobiles</h1>
                    <img className="industry-img" src={autoimg} alt="" />
                  </div>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="industry-container-col2 p-5">
                  <h5 className="pt-5">
                    We specialize in delivering bespoke solutions tailored for
                    automotive enterprises, including:
                  </h5>
                  <ul>
                    <li>
                      Custom software development for inventory management,
                      sales tracking, and customer relationship management
                      (CRM).
                    </li>
                    <li>
                      Mobile applications for sales representatives and service
                      technicians to access vehicle information and manage
                      appointments on the go.
                    </li>
                    <li>
                      Augmented Reality (AR) applications for virtual showroom
                      experiences and interactive vehicle demonstrations.
                    </li>

                    <li>
                      IoT solutions for real-time monitoring of vehicle
                      performance, predictive maintenance, and fleet management.
                    </li>
                    <li>
                      Supply chain management systems to optimize procurement,
                      inventory tracking, and logistics for automotive parts and
                      accessories.
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {/* banking part */}

        <div className="">
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <div className="industry-container-col1">
                  <div className="text-center pt-5 img-div ">
                    <h1>Banking</h1>
                    <img className="industry-img" src={bankimg} alt="" />
                  </div>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="industry-container-col2 p-5">
                  <h5 className="pt-5">
                    We offer comprehensive software solutions designed
                    specifically for financial institutions, covering:
                  </h5>
                  <ul>
                    <li>
                      Custom software development for banking operations,
                      including account management, transaction processing, and
                      risk assessment.
                    </li>
                    <li>
                      Mobile banking applications for customers to access
                      accounts, make transactions, and receive personalized
                      financial insights.
                    </li>
                    <li>
                      AI-powered chatbots for customer support, providing
                      assistance with account inquiries, loan applications, and
                      financial advice.
                    </li>

                    <li>
                      Blockchain solutions for secure and transparent
                      transactions, smart contracts, and identity verification.
                    </li>
                    <li>
                      Compliance and regulatory software to ensure adherence to
                      industry standards and mitigate risks associated with
                      fraud and money laundering.
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </section>
  );
};

export default Industrybody;
