import React from "react";
import "../Aboutus/Aboutus.css";

//import expvideo from "../Assets/expvideo4.mp4";
//import Button from "react-bootstrap/Button";

const Aboutus = () => {
  return (
    // <div className="video-background">
    //   <video autoPlay loop muted>
    //     <source src={expvideo} type="video/mp4" />
    //     Your browser does not support the video tag.
    //   </video>
    //   <Container>
    //     <Row>
    //       <Col>
    //         <h1 className="text-overlay">Your Text Overlay</h1>
    //       </Col>
    //     </Row>
    //   </Container>
    // </div>

    <section>
      <div className="softdevbanner-container">
        {/* <video autoPlay loop muted className="softdevbanner-container-video">
          <source src={expvideo} type="video/mp4" className="video" />
          Your browser does not support the video tag.
        </video> */}
        <div className="softdevbanner-container-content text-center text-white">
          <div className=" softdevbanner-button text-center p-3">
            <a
              href="https://wa.me/+919618800449"
              rel="noreferrer"
              target="_blank"
            >
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Aboutus;