import React from "react";
import "../Weoffer/Weoffer.css";

const Weoffer = (offers) => {
  return (
    <section>
      <div className="weoffer-container bg-white border border-2 py-2">
        <img src={offers.image} alt="" />
        <h4>{offers.heading}</h4>
        <p className="px-3">{offers.description}</p>
      </div>
    </section>
  );
};

export default Weoffer;
