import React from "react";
import "../Uioffers/Uioffers.css";
import Weoffer from "../Weoffer/Weoffer";
import testoffers1 from "../Assets/testoffers1.png";
import testoffers2 from "../Assets/testoffers2.png";
import testoffers3 from "../Assets/testoffers3.png";
import testoffers4 from "../Assets/testoffers4.png";
import testoffers5 from "../Assets/testoffers5.png";
import testoffers6 from "../Assets/testoffers6.png";

const uidata = [
  {
    id: 1,
    image: testoffers1,
    heading: "Comprehensive System Verification",
    description:
      "Ensure the robustness and reliability of your system with our comprehensive system testing services. Our expert testers meticulously assess the entire system's functionality, performance, and integration, identifying and addressing any potential issues to guarantee seamless operation. ",
  },
  {
    id: 2,
    image: testoffers2,
    heading: "Rigorous Security Assessment",
    description:
      "Safeguard your software against cyber threats and vulnerabilities with our thorough security testing services. Our experienced security testers conduct in-depth assessments to identify and mitigate potential security risks, ensuring that your software remains resilient and protected against unauthorized access and malicious attacks. ",
  },
  {
    id: 3,
    image: testoffers3,
    heading: "Functional Verification",
    description:
      "Validate the functionality and behavior of your software application with our meticulous functional testing services. Our skilled testers systematically evaluate each feature and component to ensure that it meets the specified requirements and delivers the intended functionality to users. ",
  },
  {
    id: 4,
    image: testoffers4,
    heading: "Dynamic & Static Code Analysis",
    description:
      "Enhance code quality and reliability with our dynamic and static code testing services. Our experienced developers and testers conduct comprehensive analyses of your codebase, identifying potential bugs, performance issues, and security vulnerabilities to ensure optimal code quality and maintainability. ",
  },
  {
    id: 5,
    image: testoffers5,
    heading: "Thorough Web & Mobile App Evaluation",
    description:
      "Ensure the optimal performance and usability of your web and mobile applications with our comprehensive testing services. Our experienced testers assess various aspects, including user interface, functionality, compatibility, and performance, to ensure that your applications deliver a seamless and satisfying user experience across different platforms and devices. ",
  },
  {
    id: 6,
    image: testoffers6,
    heading: "Load & Volume Assessment",
    description:
      "Validate the scalability and performance of your software application under heavy loads with our load and volume testing services. Our expert testers simulate real-world scenarios to assess your application's performance, identifying any bottlenecks or performance issues and ensuring that it can handle high volumes of users and transactions . ",
  },
];

const TestingOffers = () => {
  return (
    <section className="bg-light pb-5 pt-5">
      <div className="pt-3 text-center">
        <h3>Tailored Software Quality Assurance Testing Solutions</h3>
        <p className="px-5">
          Experience our specialized software QA testing services designed to
          ensure the flawless performance and reliability of your software
          applications. Our dedicated team of QA experts conducts comprehensive
          testing across all aspects of your software, including functionality,
          usability, compatibility, and security. With our exclusive QA testing
          services, you can rest assured that your software meets the highest
          standards of quality, delivering a seamless user experience to your
          customers.
        </p>
      </div>
      <div className=" uioffer-container">
        {uidata.map((offers, index) => (
          <Weoffer
            key={index}
            image={offers.image}
            heading={offers.heading}
            description={offers.description}
          />
        ))}
      </div>
    </section>
  );
};

export default TestingOffers;
