import React from "react";
import "../Uiskills/Uiskills.css";
import softskill1 from "../Assets/softskill1.png";
import softskill2 from "../Assets/softskill2.png";
import softskill3 from "../Assets/softskill3.png";
import softskill4 from "../Assets/softskill4.png";
import Ourskills from "../Ourskills/Ourskills";

const softDevSkillData = [
  {
    id: 1,
    image: softskill1,
    heading: "Comprehensive Expertise and Proficiency",
    description:
      "Benefit from our extensive skill set and deep industry knowledge, ensuring that your project is handled by seasoned professionals with the right expertise to meet your specific requirements and exceed your expectations. ",
  },
  {
    id: 2,
    image: softskill4,
    heading: "Timely Delivery and Cost-Efficiency",
    description:
      "Rest assured that your project will be completed on schedule and within budget, thanks to our commitment to efficient project management practices and transparent communication throughout the development process. ",
  },
  {
    id: 3,
    image: softskill3,
    heading: "Uncompromising Quality and Security",
    description:
      "Our rigorous quality assurance protocols and robust security measures guarantee that your software product is of the highest caliber, delivering superior performance, reliability, and protection against potential vulnerabilities. ",
  },
  {
    id: 4,
    image: softskill2,
    heading: "Transparent and Dedicated Collaboration",
    description:
      "Experience transparency and unwavering dedication in our partnership approach, as we work closely with you to understand your needs, address your concerns, and deliver solutions that drive tangible results for your business. ",
  },
];

const Softdevskills = () => {
  return (
    <section className="bg-light py-3 ">
      <div className="pt-5 text-center ">
        <h3>
          Why Select cornerstone for Your Software Product Development Needs?
        </h3>
        <p className="px-5">
          Choose cornerstone as your software product development partner for
          unparalleled expertise, commitment to quality, and dedication to
          client success. With a proven track record of delivering innovative
          solutions across diverse industries, we offer tailored services that
          align perfectly with your business objectives. Trust our experienced
          team to bring your vision to life with cutting-edge technology and
          personalized support every step of the way.
        </p>
      </div>
      <div className=" uiskills-container pt-3">
        {softDevSkillData.map((skills, index) => (
          <Ourskills
            key={index}
            image={skills.image}
            heading={skills.heading}
            description={skills.description}
          />
        ))}
      </div>
    </section>
  );
};

export default Softdevskills;
