import React from "react";
import "../Hometech/Hometech.css";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import frontimg from "../Assets/frontend.png";
import backendimg from "../Assets/backend.png";
import mobileimg from "../Assets/mobiletech.png";
import dataimg from "../Assets/database.png";
import cloudimg from "../Assets/cloud.png";
import wordpressimg from "../Assets/wordpress.png";
import Heading from "../Heading/Heading";

const Hometech = () => {
  return (
    <section className="bg-light pt-5">
      <div className=" text-center">
        <Heading
          heading="Software Technology Services We Offer"
          description=" Cornerstone Technologies provides comprehensive software development
          solutions for your business. Whether you need custom software, mobile
          app, security enhancement, or internet ranking improvement, we have
          you covered. Trust our skills and knowledge to deliver a high-quality
          business solution."
        />

        <Container>
          <Row className="justify-content-center gx-4">
            <Col sm={12} md={6} lg={4} className="mb-3 ">
              <div className="border border-2  bg-white pb-5 hometech-col">
                <img src={frontimg} alt="" />
                <h4>Frontend Technologies</h4>
                <p className="hometech-text-content ">
                  Frontend technologies encompass tools and languages like HTML,
                  CSS, and JavaScript. Frameworks such as React, Angular, and
                  Vue.js streamline frontend development. Responsive design
                  ensures websites adapt to various devices and screen sizes.
                  CSS preprocessors like Sass and Less enhance styling
                  capabilities. Web accessibility practices ensure inclusivity
                  for all users. Progressive web apps (PWAs) combine web and
                  mobile app features for a seamless experience. Frontend
                  testing frameworks like Jest and Mocha validate code
                  integrity. Web performance optimization techniques improve
                  page load times and user experience. UI/UX design principles
                  focus on user interaction and visual aesthetics. Continuous
                  integration and deployment (CI/CD) pipelines automate the
                  frontend development workflow.
                </p>
                <div className="hometech-link">
                  <Link className="knowmorelink " to={"/technologies"}>
                    <p>Know more ...</p>
                  </Link>
                </div>
              </div>
            </Col>
            <Col sm={12} md={6} lg={4} className=" mb-3">
              <div className="border border-2 bg-white pb-5 hometech-col">
                <img src={backendimg} alt="" />
                <h4>Backend Technologies</h4>
                <p className="hometech-text-content ">
                  Backend technologies manage server-side operations and data
                  processing. Programming languages such as Python, JavaScript
                  (Node.js), and Java power backend development. Frameworks like
                  Express (for Node.js) and Django (for Python) streamline
                  backend coding. RESTful APIs facilitate communication between
                  frontend and backend systems. Database management systems
                  (DBMS) like MySQL, PostgreSQL, and MongoDB store and retrieve
                  data. Authentication and authorization mechanisms ensure
                  secure access to backend resources. Caching strategies improve
                  performance by storing frequently accessed data. Backend
                  testing frameworks like Mocha, Chai, and Pytest verify backend
                  functionality. Microservices architecture enhances scalability
                  and maintainability of backend systems. Serverless computing
                  platforms like AWS Lambda and Google Cloud Functions simplify
                  backend deployment.
                </p>
                <div className="hometech-link">
                  <Link className="knowmorelink " to={"/technologies"}>
                    <p>Know more ...</p>
                  </Link>
                </div>
              </div>
            </Col>
            <Col sm={12} md={6} lg={4} className="mb-3 ">
              <div className="border border-2 bg-white pb-5 hometech-col">
                <img src={mobileimg} alt="" />
                <h4>Mobile Technologies</h4>
                <p className="hometech-text-content ">
                  Mobile technologies encompass platforms, frameworks, and tools
                  for building mobile applications. Native app development
                  involves using platform-specific languages like Swift (iOS)
                  and Kotlin (Android). Cross-platform frameworks like React
                  Native and Flutter enable building apps for multiple platforms
                  with a single codebase. Progressive web apps (PWAs) provide
                  native-like experiences on mobile web browsers. Mobile app
                  testing frameworks like Appium and XCTest ensure app
                  functionality across devices. Offline support and data
                  synchronization mechanisms enhance mobile app usability. Push
                  notifications engage users and provide timely updates. Mobile
                  analytics tools track user behavior and app performance
                  metrics. Mobile app security measures protect sensitive user
                  data and prevent unauthorized access. Augmented reality (AR)
                  and virtual reality (VR) technologies offer immersive mobile
                  experiences.
                </p>
                <div className="hometech-link ">
                  <Link className="knowmorelink " to={"/technologies"}>
                    <p>Know more ...</p>
                  </Link>
                </div>
              </div>
            </Col>

            <Col sm={12} md={6} lg={4} className="mb-3">
              <div className="border border-2 bg-white pb-5 hometech-col">
                <img src={dataimg} alt="" />
                <h4>Databases</h4>
                <p className="hometech-text-content ">
                  Databases are crucial for storing, managing, and retrieving
                  structured data efficiently. Relational databases like MySQL,
                  PostgreSQL, and SQL Server organize data into tables with
                  predefined schemas. NoSQL databases such as MongoDB and
                  Cassandra offer flexible schema designs and horizontal
                  scalability. ACID (Atomicity, Consistency, Isolation,
                  Durability) properties ensure data integrity in relational
                  databases. CAP theorem states that a distributed system cannot
                  simultaneously provide consistency, availability, and
                  partition tolerance. Database normalization techniques
                  eliminate data redundancy and maintain data integrity.
                  Indexing improves database query performance by enabling
                  faster data retrieval. Transactions ensure that a series of
                  database operations succeed or fail together as a single unit.
                  Data warehousing solutions like Amazon Redshift and Google
                  BigQuery analyze large datasets for business intelligence.
                  Database backups and disaster recovery plans mitigate the risk
                  of data loss and system failures.
                </p>
                <div className="hometech-link">
                  <Link className="knowmorelink " to={"/technologies"}>
                    <p>Know more ...</p>
                  </Link>
                </div>
              </div>
            </Col>
            <Col sm={12} md={6} lg={4} className="mb-3 ">
              <div className="border border-2 bg-white pb-5 hometech-col">
                <img src={cloudimg} alt="" />
                <h4>Cloud Services</h4>
                <p className="hometech-text-content ">
                  Cloud services provide on-demand access to computing resources
                  over the internet. Infrastructure as a Service (IaaS) offers
                  virtualized computing resources like servers and storage.
                  Platform as a Service (PaaS) provides development and
                  deployment environments for applications. Software as a
                  Service (SaaS) delivers software applications over the
                  internet on a subscription basis. Public cloud providers like
                  AWS, Azure, and Google Cloud Platform offer scalable and
                  flexible cloud solutions. Hybrid cloud deployments combine
                  on-premises infrastructure with public and private cloud
                  services. Cloud migration strategies help organizations move
                  existing applications and data to the cloud. Containerization
                  technologies like Docker and Kubernetes streamline application
                  deployment and management. Serverless computing abstracts
                  server management and scaling, allowing developers to focus on
                  code. Cloud security measures like encryption, identity and
                  access management (IAM), and network security protect cloud
                  resources.
                </p>
                <div className="hometech-link">
                  <Link className="knowmorelink " to={"/technologies"}>
                    <p>Know more ...</p>
                  </Link>
                </div>
              </div>
            </Col>
            <Col sm={12} md={6} lg={4} className="mb-3 ">
              <div className="border border-2 bg-white pb-5 hometech-col">
                <img src={wordpressimg} alt="" />
                <h4>WordPress Development</h4>
                <p className="hometech-text-content ">
                  WordPress is a popular open-source content management system
                  (CMS) for building websites and blogs. Themes customize the
                  design and layout of WordPress websites, offering pre-designed
                  templates. Plugins extend WordPress functionality, adding
                  features like contact forms, SEO optimization, and e-commerce.
                  Custom post types and taxonomies organize content beyond
                  traditional posts and pages. Gutenberg editor introduces
                  block-based content editing, simplifying layout customization.
                  WordPress REST API enables interaction with WordPress data
                  using standard HTTP requests. WordPress multisite feature
                  allows managing multiple websites from a single WordPress
                  installation. WooCommerce plugin transforms WordPress into a
                  full-fledged e-commerce platform. WordPress security measures
                  like strong passwords, security plugins, and regular updates
                  protect against vulnerabilities. WordPress performance
                  optimization techniques like caching, image optimization, and
                  content delivery networks (CDNs) improve website speed and
                  user experience.
                </p>
                <div className="hometech-link">
                  <Link className="knowmorelink " to={"/technologies"}>
                    <p>Know more ...</p>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
};

export default Hometech;
