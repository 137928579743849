import React from "react";
import "../Uxbanner/Ubanner.css";

import uibannerimg from "../Assets/uibanner.jpg";
import Button from "react-bootstrap/Button";

const Uxbanner = () => {
  return (
    <section>
      <div className="uxbanner-container">
        <img src={uibannerimg} alt="" />
        <div className="uxbanner-container-content  ">
          <h1>UI UX Design </h1>
          <p className="pt-3">
            We offer a variety of web application development services to suit
            your business needs. Whether you need a website, a SaaS product, or
            a progressive web app, we have the expertise and experience to
            deliver high-quality solutions. Our web application development
            services include design, development, testing, deployment, support,
            and maintenance. We offer a variety of web application development
            services to suit your business needs. Whether you need a website, a
            SaaS product, or a progressive web app, we have the expertise and
            experience to deliver high-quality solutions. Our web application
            development services include design, development, testing,
            deployment, support, and maintenance.
          </p>
          <div className=" text-center p-3">
            <a
              href="https://wa.me/+919618800449"
              rel="noreferrer"
              target="_blank"
            >
              <Button variant="light" className="rounded-5 px-4 py-2">
                Let's Talk
              </Button>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Uxbanner;
