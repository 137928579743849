import React from "react";
import Softdevbanner from "../Components/Softdevbanner/Softdevbanner";
import Softdevoffers from "../Components/Softdevoffers/Softdevoffers";
import Webdevbody from "../Components/WebdevBody/Webdevbody";
import Softdevskills from "../Components/Softdevskills/Softdevskills";
import Homecontact from "../Components/Homecontact/Homecontact";

const Sofrdev = () => {
  return (
    <div>
      <Softdevbanner />
      <Softdevoffers />
      <Webdevbody />
      <Homecontact />
      <Softdevskills />
    </div>
  );
};

export default Sofrdev;
