import React from "react";
import "../Techbanner/Techbanner.css";

import Button from "react-bootstrap/Button";

const Techbanner = () => {
  return (
    <>
      <div className="tech-container container-fluid">
        <div className="tech-content text-white">
          <h1 className="text-center"> Software Development Technologies</h1>

          <p className="techbanertex.jsx text-center pt-3">
          Software development technologies encompass a range of tools and frameworks 
          used to create applications. Common technologies include programming languages
          like .Net, Python, Java, and JavaScript, along with frameworks such as React, Django,
          and Spring. DevOps practices integrate tools like Docker and Kubernetes for efficient 
          deployment and scaling. Agile methodologies facilitate iterative development cycles, 
          enhancing collaboration and responsiveness to change. Continuous integration/continuous 
          deployment (CI/CD) pipelines automate testing and deployment, ensuring software 
          quality and rapid delivery.
          </p>

          <div className=" text-center p-3">
            <a
              href="https://wa.me/+919618800449"
              rel="noreferrer"
              target="_blank"
            >
              <Button variant="light" className="rounded-5   px-4 py-2">
                Let's Talk
              </Button>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Techbanner;
