import React from "react";
import "../Homeabout/Homeabout.css";
import { Container, Row, Col } from "react-bootstrap";
import homeaboutimg from "../Assets/homeabout.png";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";


const Homeabout = () => {
  return (
    <section className="homeabout-section py-5 ">
      <Container>
        <Row>
          <Col xs={12} md={12} lg={6}>
            <div className="text-center homeabout-img">
              <img src={homeaboutimg} alt="" />
            </div>
          </Col>
          <Col xs={12} md={12} lg={6}>
            <div className="homeabout-content  px-5 py-2 ">
              <h5>About Us</h5>
              <p>
                Cornerstone Technologies stands as a renowned figure in the
                realm of software development, boasting a rich legacy of over a
                decade. Our steadfast commitment to delivering cutting-edge
                solutions has earned us acclaim across industries. With a
                stellar lineup comprising adept designers, developers,
                marketers, testers, and project managers, we consistently exceed
                expectations while adhering to industry benchmarks.
              </p>
              <p>
                Founded in 2015 under the visionary leadership of Mr. Suresh,
                our mission at Cornerstone Technologies has been clear: to
                empower businesses in attaining their technological aspirations.
                Since inception, we have evolved into a trusted ally for
                startups, small, medium, and large enterprises alike.
              </p>
              <p>
                At Cornerstone Technologies, we are on a mission to
                revolutionize business operations through innovative and
                cost-effective digitization solutions. Our team remains at the
                forefront of technological advancements, ensuring that we offer
                nothing short of excellence to our clients.
              </p>
              <p>
                What sets us apart is our unwavering passion, integrity, and
                penchant for thinking outside the box. With our blend of
                dedication, enthusiasm, and expertise, we turn ideas into
                tangible realities, driving success for our clients.
              </p>
            </div>
            <div className="text-center pt-3 pb-5">
              <Link to={"/aboutus"}>
                <Button variant="dark " className="rounded-5 px-4">
                  Know More
                </Button>
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Homeabout;
