import React from "react";
import "../Hiring/hiring.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import hoursimg from "../Assets/hours.png";
import weekimg from "../Assets/week.png";
import monthimg from "../Assets/month.png";
import projectimg from "../Assets/project.png";

const Hiring = () => {
  return (
    <section className="bg-dark pt-5 hiring-container pb-4">
      <h3 className="text-white text-center pt-3">
        How to Get the Right Web App Developers for Your Business Goals
      </h3>

      <Container className="pt-5">
        <Row className="justify-content-center gx-4">
          <Col sm={12} md={10} lg={6} className="mb-3 ">
            <div className=" text-center border border-2 bg-white hiring-content">
              <h4 className="text-start">
                <img className="px-3" src={hoursimg} alt="" />
                Hourly Basis
              </h4>
              <p className=" hiring-text-content px-3 ">
                Our hourly hiring model offers flexible and cost-effective
                solutions for specific web development needs. Pay only for the
                actual hours worked, allowing precise control over project
                costs. Ideal for projects with limited scope, this model lets
                you engage developers as needed. For instance, creating a
                responsive, interactive landing page becomes efficient with our
                developers' expertise. Tailor the duration of engagement to suit
                your requirements precisely. With hourly hiring, you gain access
                to skilled developers without long-term commitments. Maximize
                efficiency and minimize expenses by leveraging our developers on
                an as-needed basis. Enjoy the freedom to scale resources
                according to project demands, optimizing productivity. Simplify
                project management and budgeting with our transparent hourly
                billing model. Experience seamless collaboration and swift
                delivery of web development tasks with our flexible hiring
                approach
              </p>
            </div>
          </Col>
          <Col sm={12} md={10} lg={6} className=" mb-3">
            <div className=" text-center border border-2 bg-white  hiring-content">
              <h4 className="text-start">
                <img className="px-3" src={weekimg} alt="" />
                Weekly Basis
              </h4>
              <p className=" hiring-text-content px-3 ">
                For small to medium-scale web development projects, our weekly
                basis hiring model is an optimal choice. By selecting this
                model, you can enlist our dedicated team of expert programmers
                proficient in .Net, PHP, Java, Ruby on Rails, and more
                programming languages on a weekly basis. This ensures a
                consistent allocation of resources for your project throughout
                the week. Our team will devote their attention exclusively to
                your project, ensuring timely progress. This model suits
                projects such as developing company websites with multiple pages
                and functionalities, allowing completion within a specified
                week. Enjoy focused attention and swift progress with our weekly
                hiring model. Benefit from a dedicated team working solely on
                your project week after week. Simplify project management with
                consistent resource allocation on a weekly basis. Experience
                efficient collaboration and prompt delivery with our dedicated
                team approach.
              </p>
            </div>
          </Col>
          <Col sm={12} md={10} lg={6} className="mb-3 ">
            <div className=" text-center border border-2 bg-white hiring-content ">
              <h4 className="text-start">
                <img className="px-3" src={monthimg} alt="" />
                Monthly Basis
              </h4>
              <p className=" hiring-text-content px-3 ">
                Our monthly hiring model is perfect for small to medium-scale
                web projects. Hire our dedicated team of skilled programmers
                proficient in various languages like .Net, PHP, Java, Ruby on
                Rails, etc., on a monthly basis. Enjoy consistent resource
                allocation for your project throughout the month. Our team will
                focus solely on your project, ensuring dedicated attention and
                timely progress. Ideal for projects like developing multi-page
                company websites. Complete your project within a specified
                timeframe with our focused approach. Benefit from a dedicated
                team working exclusively on your project month-round. Ensure
                timely delivery and focused attention with our monthly hiring
                model. Simplify project management with a consistent resource
                allocation strategy. Experience seamless progress and efficient
                collaboration with our dedicated team.
              </p>
            </div>
          </Col>
          <Col sm={12} md={10} lg={6} className="mb-3 ">
            <div className=" text-center border border-2 bg-white  hiring-content">
              <h4 className="text-start">
                <img className="px-3" src={projectimg} alt="" />
                Fixed Project Basis
              </h4>
              <p className=" hiring-text-content px-3 ">
                Our fixed project basis hiring model is ideal for large-scale
                web development projects. Collaboratively define project scope,
                timeline, and cost upfront for clarity and efficiency. Ensure
                comprehensive planning and execution by agreeing on fixed
                parameters. Perfect for complex projects such as extensive
                e-commerce platforms. Enjoy a clear understanding of project
                parameters for effective budgeting and management. Simplify
                project oversight with predetermined scope and cost. Streamline
                project execution with our fixed project model. Ideal for
                projects requiring multiple features and integrations. Benefit
                from transparent and streamlined project management. Experience
                efficient planning and execution with our tailored approach
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Hiring;
