import React from "react";
import "../Homeindustry/Homeindustry.css";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import conicon from "../Assets/conicon.png";
import eduicon from "../Assets/educationicon.png";
import healthicon from "../Assets/healthicon.png";
import manicon from "../Assets/manuicon.png";
import agriicon from "../Assets/agriicon.png";
import bankicon from "../Assets/bankicon.png";
import econicon from "../Assets/ecomicon.png";
import autoicom from "../Assets/autoicon.png";
import Heading from "../Heading/Heading";

const Homeindustry = () => {
  return (
    <section className="bg-light pt-5">
      <div className="">
        <Heading
          heading="Industries We Serve"
          description="  We create custom software solutions for any industry. Whether you need
          a web app, a mobile app, or a desktop app, we can build it for you. We
          use the latest technologies and best practices to deliver high-quality
          software that meets your specific needs and goals. No matter what
          industry you are in, we can help you grow your business with software."
        />
      </div>
      <Container>
        <Row className="gx-3">
          <Col
            className="homeindustry-container-col text-center "
            sm={6}
            md={4}
            lg={3}
          >
            <div className="homeindustry-container-col-div border border-1 rounded">
              <img
                className="homeindustry-container-img"
                src={conicon}
                alt=""
              />
              <h5>Constuction</h5>
              <p className="px-2 ">
                Web solutions streamline project management, communication, and
                client engagement, enhancing efficiency and transparency in
                construction projects.
              </p>
            </div>
          </Col>

          <Col
            className="homeindustry-container-col text-center "
            sm={6}
            md={4}
            lg={3}
          >
            <div className="homeindustry-container-col-div border border-1 rounded">
              <img
                className="homeindustry-container-img "
                src={eduicon}
                alt=""
              />
              <h5>Education Sector</h5>
              <p className="px-2 ">
                Custom web platforms facilitate online learning, student
                management, and administrative tasks, fostering interactive and
                accessible educational experiences for students and educators.
              </p>
            </div>
          </Col>
          <Col
            className="homeindustry-container-col text-center "
            sm={6}
            md={4}
            lg={3}
          >
            <div className="homeindustry-container-col-div border border-1 rounded">
              <img
                className="homeindustry-container-img"
                src={healthicon}
                alt=""
              />
              <h5>Healthcare</h5>
              <p className="px-2 ">
                Secure web applications enable electronic health records
                management, telemedicine consultations, and appointment
                scheduling, improving patient care coordination and healthcare
                accessibility.
              </p>
            </div>
          </Col>
          <Col
            className="homeindustry-container-col text-center "
            sm={6}
            md={4}
            lg={3}
          >
            <div className="homeindustry-container-col-div border border-1 rounded">
              <img
                className="homeindustry-container-img"
                src={manicon}
                alt=""
              />
              <h5>Manufacturing</h5>
              <p className="px-2 ">
                Web-based systems optimize supply chain management, production
                planning, and inventory tracking, enhancing operational
                efficiency and real-time decision-making in manufacturing
                processes.
              </p>
            </div>
          </Col>

          <Col
            className="homeindustry-container-col text-center "
            sm={6}
            md={4}
            lg={3}
          >
            <div className="homeindustry-container-col-div border border-1 rounded">
              <img
                className="homeindustry-container-img"
                src={agriicon}
                alt=""
              />
              <h5>Agriculture</h5>
              <p className="px-2 ">
                Web technologies support precision farming, crop monitoring, and
                market analysis, empowering farmers with data-driven insights
                for improved yield, resource utilization, and sustainability in
                agricultural practices.
              </p>
            </div>
          </Col>

          <Col
            className="homeindustry-container-col text-center "
            sm={6}
            md={4}
            lg={3}
          >
            <div className="homeindustry-container-col-div border border-1 rounded">
              <img
                className="homeindustry-container-img "
                src={bankicon}
                alt=""
              />
              <h5>Finance and Banking</h5>
              <p className="px-2 ">
                Secure web portals offer online banking, financial planning, and
                investment management services, providing customers with
                convenient access to their accounts and personalized financial
                solutions.
              </p>
            </div>
          </Col>
          <Col
            className="homeindustry-container-col text-center "
            sm={6}
            md={4}
            lg={3}
          >
            <div className="homeindustry-container-col-div border border-1 rounded">
              <img
                className="homeindustry-container-img"
                src={econicon}
                alt=""
              />
              <h5>Ecommerce</h5>
              <p className="px-2 ">
                Robust web platforms enable online stores, product catalog
                management, and secure payment processing, delivering seamless
                shopping experiences and driving sales growth for businesses.
              </p>
            </div>
          </Col>
          <Col
            className="homeindustry-container-col text-center "
            sm={6}
            md={4}
            lg={3}
          >
            <div className="homeindustry-container-col-div border border-1 rounded">
              <img
                className="homeindustry-container-img"
                src={autoicom}
                alt=""
              />
              <h5>Automobiles</h5>
              <p className="px-2 ">
                Web applications facilitate vehicle inventory management, online
                sales platforms, and service scheduling, enhancing customer
                engagement and operational efficiency for automotive dealerships
                and service centers.
              </p>
            </div>
          </Col>
        </Row>
      </Container>

      <div className="text-center pt-3 pb-5">
        <Link to={"/industry"}>
          <Button variant="dark" className="rounded-5 px-5">
            Know More
          </Button>
        </Link>
      </div>
    </section>
  );
};

export default Homeindustry;
