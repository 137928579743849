import { useState } from "react";
import React from "react";
import "../Contactform/Contactform.css";
import { Container, Row, Col } from "react-bootstrap";

import { MdEmail } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";
import { IoLocation } from "react-icons/io5";

const Contactform = () => {
  const [formData, setFormData] = useState({
    fullname: "",
    company: "",
    email: "",
    mobilenumber: "",
    address: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const message = `Name: ${formData.fullname}%0ACompany: ${formData.company}%0AEmail: ${formData.email}%0AMobile: ${formData.mobilenumber}%0AMessage: ${formData.message}`;
    const whatsappLink = `https://wa.me/+918341933684?text=${message}`;
    window.open(whatsappLink, "_blank");
    setFormData({
      fullname: "",
      company: "",
      email: "",
      mobilenumber: "",
      address: "",
      message: "",
    });
  };

  return (
    <section className="contactform-section py-5">
      <Container>
        <Row>
          <Col xs={12} md={5}>
            <div className="contactform-address text-start">
              <h1>Let's Talk</h1>
              <h5>Join us for a business discussion!</h5>
              <p className="p-0">
                Boost productivity with innovative software maintenance.
              </p>
              <div className="address text-start">
                <p className="">
                  <MdEmail size={22} />
                  Email Us:
                  <b className="ps-2">
                    <a
                      href="mailto:lavanya415@gmail.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      info@cornerstoneaitechnologies.in
                    </a>
                  </b>
                </p>
                <p>
                  <FaPhoneAlt size={20} />
                  Call Us: <b className="ps-2">+91 8341933684</b>
                </p>
                <p>
                  <IoLogoWhatsapp size={20} /> Whatsapp:
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://wa.me/+918341933684"
                  >
                    <b className="ps-2">+91 8341933684</b>
                  </a>
                </p>
                <p>
                  <IoLocation size={20} /> Location:{" "}
                  <b className="ps-2">Darsi, Ap</b>
                </p>
              </div>
            </div>
          </Col>
          <Col xs={12} md={7}>
            <div className="">
              <form onSubmit={handleSubmit} className="row g-3">
                <div className="col-md-6">
                  <label className="form-label">Full Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="fullname"
                    required
                    value={formData.fullname}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6">
                  <label className="form-label">Company Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="company"
                    required
                    value={formData.company}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6">
                  <label className="form-label">Email</label>
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    required
                    value={formData.email}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6">
                  <label className="form-label">Phone Number</label>
                  <input
                    type="number"
                    className="form-control"
                    name="mobilenumber"
                    required
                    value={formData.mobilenumber}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-12">
                  <label className="form-label">Address</label>
                  <input
                    type="text"
                    className="form-control"
                    name="address"
                    required
                    value={formData.address}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-12">
                  <label className="form-label">Message</label>
                  <textarea
                    className="form-control"
                    name="message"
                    required
                    value={formData.message}
                    onChange={handleChange}
                  ></textarea>
                </div>
                <div className="col-12">
                  <button
                    type="submit"
                    value="submit"
                    className="btn btn-info  px-4"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Contactform;
