import React from "react";
import TestingOffers from "../Components/Testingoffers/TestingOffers";
import Testingbanner from "../Components/Testingbanner/Testingbanner";
import Testingtypes from "../Components/Testingtypes/Testingtypes";
import Homecontact from "../Components/Homecontact/Homecontact";
import Homeindustry from "../Components/Homeindustry/Homeindustry";

const Testing = () => {
  return (
    <div>
      <Testingbanner />
      <TestingOffers />
      <Testingtypes />
      <Homecontact />
      <Homeindustry />
    </div>
  );
};

export default Testing;
