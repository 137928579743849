import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import iosimg from "../Assets/ios.png";
import andimg from "../Assets/android.png";
import fluimg from "../Assets/flutter.png";

const Mobileapptech = () => {
  return (
    <section className="bg-dark pt-5">
      <div className="container-fluid text-center pb-5">
        <h3 className="pt-4 text-white">Mobile Technologies</h3>
        <p className="text-white px-5">
          Enable seamless access to your premium application over the internet,
          reaching your target audience regardless of their device, browser, or
          operating system. Empower business growth by ensuring every individual
          user can effortlessly engage with your offerings.
        </p>

        <Container>
          <Row className="justify-content-center gx-4">
            <Col sm={8} md={4} className="mb-3 ">
              <div className="border border-2 bg-white techbody-col ">
                <img src={iosimg} alt="" />
                <h4>iOS</h4>
                <p className="text-content ">
                  iOS is a mobile operating system developed by Apple Inc.
                  exclusively for its smartphones. It was unveiled in January
                  2007 for the first-generation iPhone, and it is the operating
                  system that powers many of the company’s mobile devices,
                  including the iPhone, iPad, iPod Touch, and Apple TV. iOS is
                  based on macOS, and it uses a multi-touch graphical user
                  interface that allows users to interact with their devices
                  using gestures such as swiping, tapping, and pinching
                </p>
              </div>
            </Col>
            <Col sm={8} md={4} className=" mb-3">
              <div className="border border-2 bg-white techbody-col ">
                <img src={andimg} alt="" />
                <h4>Android</h4>
                <p className="text-content ">
                  Android is a mobile operating system based on a modified
                  version of the Linux kernel and other open-source software. It
                  is designed primarily for touchscreen devices such as
                  smartphones and tablets. It is developed by Google and a
                  consortium of developers known as the Open Handset Alliance.
                  Android allows users to customize their devices with various
                  apps and games from the Google Play Store or other sources.
                  Android also supports various features,
                </p>
              </div>
            </Col>
            <Col sm={8} md={4} className="mb-3 ">
              <div className="border border-2 bg-white techbody-col">
                <img src={fluimg} alt="" />
                <h4>Flutter</h4>
                <p className="text-content ">
                  Flutter is a cross-platform framework that allows you to
                  create native and web applications using a single codebase. It
                  is developed by Google and uses the Dart programming language.
                  Flutter has a component-based architecture that lets you
                  create reusable and modular UI elements called widgets.
                  Flutter also has a hot reload and hot restart feature that
                  enables fast and easy development and testing. Flutter is
                  open-source and has a large and active community of
                  developers.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
};

export default Mobileapptech;
