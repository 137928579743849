import React from "react";
import "../Uioffers/Uioffers.css";
import Weoffer from "../Weoffer/Weoffer";
import image1 from "../Assets/planing.png";
import image2 from "../Assets/desgining.png";
import image3 from "../Assets/development.png";
import image4 from "../Assets/testing.png";
import image5 from "../Assets/launch.png";
import image6 from "../Assets/support.png";
import Heading from "../Heading/Heading";

const workFlowData = [
  {
    id: 1,
    image: image1,
    heading: "Planning",
    description:
      "Strategic project planning ensures clear objectives, timelines, and resource allocation for successful web development initiatives.  ",
  },
  {
    id: 2,
    image: image2,
    heading: "Designing",
    description:
      "Creative design expertise translates concepts into visually appealing and user-friendly web interfaces, aligning with brand identity and user experience goals.  ",
  },
  {
    id: 3,
    image: image3,
    heading: "Development",
    description:
      "Proficient coding skills and technology expertise drive the creation of functional, scalable, and secure web applications tailored to client requirements. ",
  },
  {
    id: 4,
    image: image4,
    heading: "Testing",
    description:
      "Rigorous testing protocols verify functionality, performance, and compatibility across devices and browsers, ensuring a seamless user experience and minimizing bugs and errors.  ",
  },
  {
    id: 5,
    image: image5,
    heading: "Launch",
    description:
      "Efficient deployment processes ensure smooth transitions from development to production environments, with comprehensive launch strategies to maximize visibility and user adoption..  ",
  },
  {
    id: 6,
    image: image6,
    heading: "Support",
    description:
      "Ongoing maintenance and support services provide timely updates, troubleshooting, and enhancements to sustain optimal performance and user satisfaction post-launch. ",
  },
];

const Homeworkflow = () => {
  return (
    <section className="py-4 bg-light">
      <div className="">
        <Heading
          heading="Our Workflow"
          description="  We orchestrate our projects using an Agile Development Approach,
          granting us the flexibility to adapt to evolving requirements. Our
          meticulously designed process ensures high-quality solutions that
          operate within the confines of business constraints."
        />
      </div>
      <div className=" uioffer-container">
        {workFlowData.map((offers, index) => (
          <Weoffer
            key={index}
            image={offers.image}
            heading={offers.heading}
            description={offers.description}
          />
        ))}
      </div>
    </section>
  );
};

export default Homeworkflow;
